export const TITLES = [
  { id: "Miss", value: "Miss", label: "Miss" },
  { id: "Mr", value: "Mr", label: "Mr" },
  { id: "Mrs", value: "Mrs", label: "Mrs" },
];

export const AGE = [
  { id: "18 to 25", value: "18 to 25", label: "18 to 25" },
  { id: "26 to 30", value: "26 to 30", label: "26 to 30" },
  { id: "31 to 40", value: "31 to 40", label: "31 to 40" },
  { id: "41 to 50", value: "41 to 50", label: "41 to 50" },
  { id: "51 to 60", value: "51 to 60", label: "51 to 60" },
  { id: "above to 60", value: "above to 60", label: "above to 60" },
];
