import styled from "styled-components";
import { Modal, Col, Row } from "react-bootstrap";

export const ModalCustom = styled(Modal)`
  background: rgba(64, 67, 120, 0.75);
  & .modal-content {
    border-radius: ${({ theme }: any) => (theme.isMobile ? "0px" : "30px")};
  }
  & .modal-dialog {
    max-width: 29rem;
  }
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    & .modal-dialog {
      max-width: 100%;
    }
  }
`;

export const DivModalHeader = styled.div`
  padding-left: 3rem;
  padding-right: 3rem;
`;

export const ModalHeader = styled(Modal.Header)`
  margin-top: 2rem;
`;

export const RowCloseModalSignIn = styled(Row)`
  justify-content: right;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: -3rem;
`;

export const SpanCloseModalSignIn = styled.span`
  position: relative;
  width: 2.5rem;
  cursor: pointer;
`;

export const ImgCloseModalSignIn = styled.img`
  content: url("/images/modalSignInSignUp/CloseBlue.svg");
  width: 1rem;
  // height: 1rem;
`;

export const RowIconSignIn = styled(Row)`
  width: 100%;
  text-align: center;
  --bs-gutter-x: 0rem;
`;

export const ColIconSignIn = styled(Col)`
  width: 100%;
  font-size: 21px;
  color: grey;
  //border-bottom: 1px solid #ddd;
`;

export const SpanIconSignIn = styled.span`
  content: url("/images/modalSignInSignUp/SignInIcon.svg");
  width: 3.5rem;
  height: 3.5rem;
`;

export const ModalBody = styled(Modal.Body)`
  padding-left: 3rem;
  padding-right: 3rem;
  margin-top: 0.7rem;
  //height: 50vh;
  //border: 1px solid red;
`;

export const InputMail = styled.input`
  position: relative;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0;
  height: 48px;
  padding-right: 55px;
  background: #f6f8fb;
  border: 1px solid #e9e9ea;
  border-radius: 5px;
  padding-left: 35px;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5c5c5c;
  display: block;
  box-sizing: border-box;
  :focus {
    outline-color: #41d976;
    outline-width: thin;
  }
`;

export const SpanIconArroba = styled.span`
  position: absolute;
  margin-top: -2.4rem;
  right: 3.8rem;
  cursor: pointer;
`;

export const ImgIconArroba = styled.img`
  content: url("/images/modalSignInSignUp/arrobaIcon.svg");
  width: 1.3rem;
`;

export const InpuPassword = styled.input`
  position: relative;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0;
  height: 48px;
  padding-right: 55px;
  background: #f6f8fb;
  border: 1px solid #e9e9ea;
  border-radius: 5px;
  padding-left: 35px;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5c5c5c;
  display: block;
  box-sizing: border-box;
  :focus {
    outline-color: #41d976;
    outline-width: thin;
  }
`;

export const SpanIconPassword = styled.span`
  position: absolute;
  margin-top: -2.4rem;
  right: 3.8rem;
  cursor: pointer;
`;

export const ImgIconPassword = styled.img`
  content: ${({ theme }: any) =>
    theme.showPass
      ? `url("/images/modalSignInSignUp/eyeon.svg")`
      : `url("/images/modalSignInSignUp/eyeoff.svg")`};

  width: 1.5rem;
`;

export const CheckBoxRememberPass = styled.input`
  top: -7px;
  left: 1px;
  padding-right: 23px;
  height: 18px;
  width: 18px !important;
  cursor: pointer;
`;

export const ColBoxRememberPass = styled(Col)`
  margin-top: -0.25rem;
`;

export const SpanBoxRememberPass = styled.span`
  font-size: 13px;
  color: #575757;
`;

export const BtnLogin = styled.button`
  width: 100%;
  height: 3rem;
  color: white;
  border-radius: 24px;
  background: #424577;
  border: none;
  margin: 10px 0 !important;
  text-align: center;
  letter-spacing: 2px;
  font-weight: 600;
  line-height: 20px;
  font-size: 14px;
`;

export const SpanForgotPass = styled.span`
  text-align: center;
  color: #424577;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer !important;
`;

export const ColOr = styled(Col)`
  top: -10px;
  width: ${({ theme }: any) => (theme.isMobile ? "40%" : "40%")};
  border-bottom: 1px solid #ddd;
  position: relative;
`;

export const ColOrTittle = styled(Col)`
  font-size: ${({ theme }: any) => (theme.isMobile ? "16px" : "16px")};
  font-weight: 400;
  text-align: center;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: grey;
  //margin: 0 12px;
`;

export const RowBtnLoginFb = styled(Row)`
  display: ${({ theme }: any) => (theme.show ? "" : "none")};
  width: 100%;
  --bs-gutter-x: 0rem;
`;
export const BtnLoginFacebook = styled.button`
  border-radius: 24px !important;
  width: 100%;
  height: 3rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  text-decoration: none;
  text-transform: none;
  background-color: #4268b3;
  border: none;
  //padding: 16px;
  margin-bottom: 8px;
  position: relative;
`;

export const SpanIConFb = styled.span`
  padding: 1px 2px 0 4px;
  margin-right: 12px;
`;

export const ImgIconFb = styled.img`
  content: url("/images/modalSignInSignUp/fb.png");
  width: 1.3rem;
  margin-right: 12px;
`;

export const RowBtnGoogle = styled(Row)`
  display: ${({ theme }: any) => (theme.show ? "" : "none")};
  width: 100%;
  --bs-gutter-x: 0rem;
`;

export const BtnLoginGoogle = styled.button`
  width: 100%;
  height: 3rem;
  text-align: center;
  border-radius: 24px !important;
  border: 1px solid #424577 !important;
  color: #4268b3 !important;
  background-color: white;

  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const SpanIConGoogle = styled.span`
  padding: 1px 2px 0 4px;
  margin-right: 12px;
`;

export const ImgIconGoogle = styled.img`
  content: url("/images/modalSignInSignUp/google.png");
  width: 1.3rem;
`;
export const DivFooderModalSignIn = styled.div`
  position: relative;
  padding-left: 3rem;
  padding-right: 3rem;
`;
export const FooderModalSignIn = styled.div`
  //border: 1px solid red;
  margin-top: -1.3rem;
  margin-bottom: 2rem;
  justify-content: center;
  border: ${({ theme }: any) => (theme.isMobile ? "" : "none")};
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    margin-top: 0rem;
  }
`;
export const RowFooder = styled(Row)`
  width: 100%;
  //--bs-gutter-x: 0rem;
  margin-top: ${({ theme }: any) => (theme.isMobile ? "-9rem" : "")};
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    margin-top: 0rem;
  }
`;
export const SpanMessage = styled.span`
  text-align: center;
  font-size: ${({ theme }: any) => (theme.isMobile ? "12px" : "14px")};
`;
export const SpanSignUp = styled.span`
  color: #575757;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
`;

export const SpanElementRequired = styled.span`
  font-size: 11px !important;
  margin-left: 3px;
  color: red;
  font-weight: 400;
`;

export const OptionClientsContainer = styled.div`
  display: ${({ theme }: any) => (theme.show ? "" : "none")};
`;
