import { IMAGE_PATH } from "@helpers/config";
import styled from "styled-components";

export const InputAutocomplete = styled.input`
  background-image: ${({ theme }) => `url(${IMAGE_PATH}${theme.icon})`};
  background-repeat: no-repeat;
  background-position: left 0.5rem center;
  background-size: 17px;
  padding-left: 2rem;
  width: 100%;
  border: 1px solid #ccc;
  font-size: 14px;
  font-weight: 600;
  color: var(--bs-gray-700);
  height: 2.5rem;
`;

export const XSpan = styled.button`
  position: absolute;
  content: url("/images/hotel-detail/gallery-popup-icons/close.svg");
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  top: 0.7rem;
  right: 0.5rem;
  z-index: 4;
`;

export const InputAutocompleteWrapper = styled.div`
  position: relative;
`;
