import axios from "axios";

export const getPaymentCardByUser = async (
  currency: string
): Promise<PaymentCardByUserResponseDataType[] | null> => {
  try {
    const response = await axios.post(`/api/booking/get-paymentcard-by-user`, {
      currency,
    });
    return response?.data?.data?.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};
