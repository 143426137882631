import countryJSON from "@data/selects/countryJSON.json";

export const getCountryById = (id: number) => {
  const countryCodes = countryJSON.data.data;
  const code = countryCodes.find((f) => Number(f.countryId) == Number(id));
  if (code) {
    return code;
  }
  return countryCodes.find((f) => Number(f.countryId) == 235); // united states
};
