import { create } from "zustand";

interface AdsState {
  showCurrencyModal: boolean;
  showLanguageModal: boolean;
  setShowCurrencyModal: (value: boolean) => void;
  setShowLanguageModal: (value: boolean) => void;
}

export const configStore = create<AdsState>((set) => ({
  showCurrencyModal: false,
  showLanguageModal: false,
  setShowCurrencyModal: (value: boolean) => {
    set({ showCurrencyModal: value });
  },
  setShowLanguageModal: (value: boolean) => {
    set({ showLanguageModal: value });
  },
}));
