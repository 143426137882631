import axios from "axios";

export const getCurrencyInfoApi =
  async (): Promise<UserDetailCurrencyResponseType | null> => {
    try {
      const response = await axios.post(`/api/layout/getCurrencyinfo`, {});
      const data = response.data;

      return {
        currency: {
          currencyDetails:
            data.currencylanguageinfoConfig.currency[0].currencyDetails,
          prefCurrency:
            data.currencylanguageinfoConfig.currency[0].prefCurrency[0],
        },
        languages: {
          langDetails: data.currencylanguageinfoConfig.languages[0].langDetails,
          prefLang: data.currencylanguageinfoConfig.languages[0].prefLang[0],
        },
      };
    } catch (error) {
      return null;
    }
  };

export const getCurrencyRate = async ({
  fromCurrency,
  toCurrency,
  all,
}: {
  fromCurrency: string;
  toCurrency: string;
  all?: boolean;
}): Promise<{ roeRate: number } | null> => {
  try {
    const response = await axios.post(`/api/general/getCurrencyRate`, {
      fromCurrency,
      toCurrency,
      all,
    });
    const data = response.data;

    return data.data;
  } catch (error) {
    return null;
  }
};

export const getAllCurrencyRate = async ({
  fromCurrency,
  toCurrency,
  all,
}: {
  fromCurrency: string;
  toCurrency: string;
  all?: boolean;
}): Promise<DataCurrencyAllTtype | undefined> => {
  try {
    const response = await axios.post(`/api/general/getCurrencyRate`, {
      fromCurrency,
      toCurrency,
      all,
    });
    const data = response.data;

    return data.data;
  } catch (error) {
    return undefined;
  }
};
