import axios from "axios";

export const getCountryDataAPI = async (): Promise<CountryListType[]> => {
  try {
    const response = await axios.post(`/api/general/getCountry`);
    return response.data.data.data;
  } catch (error) {
    return [];
  }
};

type ActivitiesProductsSearchType = {
  url: string;
};

export const fetcherCountry = async (params: ActivitiesProductsSearchType) => {
  return axios.post(params.url).then((res) => res.data.data.data);
};
