// check isEnableLoaderGPT from local storage
export const isEnableLoaderGPT = () => {
  try {
    if (typeof window == "undefined" || !localStorage) {
      return;
    }
    return localStorage.getItem("isEnableLoaderGPT") === "true";
  } catch (error) {
    console.error("Error in getCurrencyInfo", error);
  }
  return false;
};

// set isEnableLoaderGPT from local storage
export const setIsEnableLoaderGPT = (value: boolean) => {
  try {
    if (typeof window == "undefined" || !localStorage) {
      return;
    }
    localStorage.setItem("isEnableLoaderGPT", value ? "true" : "false");
  } catch (error) {
    console.log("🚀 ~ setIsEnableLoaderGPT ~ error:", error);
  }
};
