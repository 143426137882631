import { useDetectClickOutside } from "react-detect-click-outside";
import { useWhiteLabelLan } from "@hooks/useLayout";
import {
  ColOptions,
  OverlayContainer,
  NotAvailableContainer,
  NotAvailableLabel,
} from "./styles";
import { Row } from "react-bootstrap";

type OverlayProps = {
  show: boolean;
  handlerClose: () => void;
  options: CountryListType[];
  handlerOnClickSuggest: (val: string, val2: string) => void;
  onChanges: (optionSelected: CountryListType) => void;
  title?: string;
  origin?: "booking_history";
};
export const Overlay: React.FC<OverlayProps> = ({
  show,
  handlerClose,
  options,
  handlerOnClickSuggest,
  onChanges,
  title,
  origin,
}) => {
  const ref = useDetectClickOutside({ onTriggered: handlerClose });
  const { getLangLabel } = useWhiteLabelLan();
  if (!show) {
    return <></>;
  }
  return (
    <OverlayContainer ref={ref} theme={{ origin }}>
      {options?.length > 0 &&
        options.map((opt, index) => {
          return (
            <Row
              key={`${index}-phone-code`}
              onClick={() => {
                handlerOnClickSuggest(
                  `${opt.countryCode} ${opt.isdCode}`,
                  opt.countryFlag
                );
                onChanges(opt);
              }}
            >
              <ColOptions
                theme={{
                  icon: opt.countryFlag,
                  activo: title === `${opt.countryCode} ${opt.isdCode}`,
                }}
              >
                {opt.countryName} {opt.countryCode} {opt.isdCode}
              </ColOptions>
            </Row>
          );
        })}
      <NotAvailableContainer theme={{ show: options?.length == 0 }}>
        <NotAvailableLabel>{`${getLangLabel(
          "NO RESULT FOUND"
        )}!`}</NotAvailableLabel>
      </NotAvailableContainer>
    </OverlayContainer>
  );
};
