import axios from "axios";

//
export const getPaymentCardByOrg = async (
  dataReq: PaymentCardByOrgApiReqType
): Promise<PaymentCardByOrgTypeDataRespType[] | null> => {
  try {
    const response = await axios.post(`/api/walletUser/getPaymentCardByOrg`, {
      ...dataReq,
    });
    return response?.data?.data?.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};
