import axios from "axios";

export const getGeolocationByIpApi =
  async (): Promise<GeoLocationByIpResponseType | null> => {
    try {
      const response = await axios.post(`/api/general/getGeolocationByIp`);
      console.info("response geo", response);
      return response.data;
    } catch (error) {
      return null;
    }
  };
