import { create } from "zustand";
interface LanguageState {
  language?: WhiteLabelLangApiType[];
  showKeysLangLabel: boolean;
  setLanguage: (value: WhiteLabelLangApiType[]) => void;
  setShowKeysLangLabel: (value: boolean) => void;
}

export const useLanguageStore = create<LanguageState>((set) => ({
  language: [],
  showKeysLangLabel: false,
  setLanguage: (value: WhiteLabelLangApiType[]) => set({ language: value }),
  setShowKeysLangLabel: (value: boolean) => set({ showKeysLangLabel: value }),
}));
