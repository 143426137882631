import {
  ModalCustom,
  ModalHeader,
  RowCloseModalSignIn,
  SpanCloseModalSignIn,
  ImgCloseModalSignIn,
  SpanIconSignIn,
  ColIconSignIn,
  RowIconSignIn,
  ModalBody,
  InputMail,
  SpanIconArroba,
  ImgIconArroba,
  InpuPassword,
  SpanIconPassword,
  ImgIconPassword,
  CheckBoxRememberPass,
  SpanBoxRememberPass,
  ColBoxRememberPass,
  BtnLogin,
  SpanForgotPass,
  ColOr,
  ColOrTittle,
  BtnLoginFacebook,
  RowBtnLoginFb,
  ImgIconFb,
  RowBtnGoogle,
  BtnLoginGoogle,
  SpanIConGoogle,
  ImgIconGoogle,
  SpanMessage,
  SpanSignUp,
  DivModalHeader,
  SpanElementRequired,
  OptionClientsContainer,
} from "./styles";
import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";

//import FacebookLogin from "react-facebook-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import AuthService from "@services/auth.service";
import { useAuth } from "@contexts/AuthContext";
import Router from "next/router";
import { useCurrencyLangInfo, useWhiteLabelLan } from "@hooks/useLayout";
import {
  FACEBOOK_APP_ID,
  GOOGLE_APP_ID,
  IS_B2B,
  USER_TYPE,
  SHOW_GOOGLE,
  SHOW_FACEBOOK,
} from "@helpers/config";
import { useMain } from "@contexts/mainContext";
import { LoaderSpin } from "@components/LoaderSpin";
import * as authHelper from "@helpers/AuthHelpers";
import { useMembershipUpgradeStore } from "@store/membershipUpgradeStore";
import { preventExecuteCodeHTMLJS } from "@helpers/helper";
import { AUTH_LOCAL_STORAGE_USER_DATE_FORMAT } from "@helpers/AuthHelpers";
type ModalSignInProps = {
  show: boolean;
  handleCloseModalSignIn: any;
  handleOpenModalForgotPass: any;
  handleOpenModalSignUp: any;
  isMobile: boolean;
  rediret?: boolean;
};

export const ModalSignIn: React.FC<ModalSignInProps> = ({
  show,
  handleCloseModalSignIn,
  handleOpenModalForgotPass,
  handleOpenModalSignUp,
  isMobile,
  rediret = true,
}) => {
  const { currencies, languages } = useCurrencyLangInfo();
  const [showPass, setShowPass] = useState(false);
  const [reqMail, setReqMail] = useState("");
  const [reqPass, setReqPass] = useState("");
  const [mail, setMail] = useState("");
  const [pass, setPass] = useState("");
  const [errorSignIn, setErrorSignIn] = useState("");
  const [rememberPass, setRememberPass] = useState(false);
  const { saveAuth, saveUser } = useAuth();
  const { getLangLabel } = useWhiteLabelLan();
  const { userType } = useMain();
  const [loader, setLoader] = useState(false);
  const { setPlanStatus } = useMembershipUpgradeStore();

  const handlerShowLoder = () => setLoader(true);
  const handlerHideLoder = () => setLoader(false);

  const evaluationInputsLogin = () => {
    setErrorSignIn("");
    if (mail.trim() == "" || pass.trim() == "") {
      mail.trim() == ""
        ? setReqMail("Please Enter Email Address")
        : setReqMail("");
      pass.trim() == "" ? setReqPass("Please Enter Password") : setReqPass("");
      return;
    }

    executeSignIn("Mail");
  };

  const executeSignIn = async (
    typeLoggin: "Facebook" | "Google" | "Mail",
    token?: string,
    mailLogged?: string,
    jsonLoggedType?: any,
    firstName?: string,
    lastName?: string
  ) => {
    handlerShowLoder();
    let jsonSign = {
      usr_type: USER_TYPE,
      email_id: typeLoggin == "Mail" ? mail : mailLogged,
      password: typeLoggin == "Mail" ? pass : "",
      googletoken: typeLoggin == "Google" ? token : "",
      fbtoken: typeLoggin == "Facebook" ? token : "",
      site_url: "",
      accesscode: "",
      isPasswordAttempt: 0,
      promotions: {
        isPromotionVisited: false,
      },
      userdetails: {
        usr_email_id: typeLoggin == "Mail" ? mail : mailLogged,
        first_name:
          typeLoggin == "Google" || typeLoggin == "Facebook" ? firstName : "",
        last_name:
          typeLoggin == "Google" || typeLoggin == "Facebook" ? lastName : "",
        mastercode: typeLoggin == "Mail" ? "4521423" : "",
      },
    };

    const navAgent = navigator.userAgent;
    const response = await axios.post(`/api/signIn/`, {
      jsonSign,
      navAgent,
    });

    const status = response?.data?.status || "";

    if (status !== "success") {
      setErrorSignIn(
        getLangLabel(
          String(
            response?.data?.message || "INVALID EMAIL ADDRESS OR PASSWORD"
          ).toUpperCase()
        )
      );
      handlerHideLoder();
      return;
    }

    const subPlans = response.data?.result?.subscription_details;

    if (subPlans?.length > 0) {
      if (subPlans && subPlans?.plan_status == "ACTIVE") {
        setPlanStatus(true);
      }
    }
    const userData = AuthService.setLogInInfo(
      response.data?.result,
      typeLoggin,
      jsonLoggedType,
      currencies,
      languages
    );
    saveAuth({
      api_token: userData.auth.api_token,
    });

    let jsonClient = {
      ...userData,
      first_name: userData.userName,
      last_name: userData.last_name,
      first_Name: userData.userName,
    };
    console.log("jsonClientSigin modalSigin", jsonClient);
    saveUser(jsonClient);
    localStorage.setItem(AUTH_LOCAL_STORAGE_USER_DATE_FORMAT, "");
    if (userData.nationality) {
      localStorage.setItem(authHelper.NATIONALITY_KEY, userData.nationality);
    }
    if (response.data?.result?.is_promotion) {
      if (rediret) {
        Router.push({
          pathname: "/promotions",
        }).then(() => Router.reload());
      } else Router.reload();
    } else {
      Router.reload();
    }

    handlerHideLoder();
    handleCloseModalSignIn();
  };
  function handleSubmit(event: any) {
    event.preventDefault();
  }

  const responseFacebook = (response: any) => {
    //console.log(`responseFacebook()_`, response);
    if (response?.accessToken) {
      executeSignIn(
        "Facebook",
        response.accessToken,
        response.email,
        response,
        response.name.split(" ")[0].trim()
          ? response.name.split(" ")[0].trim()
          : response.name,
        response.name.split(" ")[1].trim()
          ? response.name.split(" ")[1].trim()
          : response.name
      );
    }
  };

  const responseGoogleSuccess = (response: any) => {
    //console.log({ response });
    executeSignIn(
      "Google",
      response.googleId, //response.tokenObj.access_token,
      response.profileObj.email,
      response,
      response.profileObj.givenName,
      response.profileObj.familyName
    );
  };

  const responseGoogleError = (/* response: any */) => {
    //console.log(`responseGoogleError()_`, response);
  };

  return (
    <ModalCustom
      show={show}
      onHide={handleCloseModalSignIn}
      fullscreen={isMobile}
      backdrop={"static"}
      keyboard={false}
      theme={{ isMobile }}
      className="modalcolors"
    >
      <RowCloseModalSignIn>
        <SpanCloseModalSignIn
          onClick={() => {
            handleCloseModalSignIn();
          }}
        >
          <ImgCloseModalSignIn />
        </SpanCloseModalSignIn>
      </RowCloseModalSignIn>
      <DivModalHeader>
        <ModalHeader>
          <RowIconSignIn>
            <Col md={12} className={"p-0"}>
              <SpanIconSignIn />
            </Col>
            <ColIconSignIn md={12} className={"p-0"}>
              {getLangLabel("LOG-IN")}
            </ColIconSignIn>
          </RowIconSignIn>
        </ModalHeader>
      </DivModalHeader>

      <ModalBody>
        {errorSignIn && (
          <SpanElementRequired>{errorSignIn}</SpanElementRequired>
        )}
        <form onSubmit={handleSubmit} autoComplete={"on"}>
          <div style={{ marginBottom: "1rem" }}>
            <InputMail
              type={"text"}
              name={"username"}
              placeholder={getLangLabel("EMAIL ADDRESS")}
              onChange={(e) => {
                setReqMail("");
                setErrorSignIn("");
                setMail(preventExecuteCodeHTMLJS(String(e.target.value || "")));
              }}
              value={mail}
              autoComplete={rememberPass ? "on" : ""}
            ></InputMail>
            <SpanIconArroba>
              <ImgIconArroba />
            </SpanIconArroba>
            {reqMail && <SpanElementRequired>{reqMail}</SpanElementRequired>}
          </div>
          <div style={{ marginBottom: isMobile ? "2rem" : "1rem" }}>
            <InpuPassword
              type={showPass ? "text" : "password"}
              name={"password"}
              placeholder={getLangLabel("PASSWORD")}
              maxLength={13}
              onChange={(e) => {
                setReqPass("");
                setErrorSignIn("");
                setPass(String(e.target.value));
              }}
              autoComplete={rememberPass ? "on" : ""}
            ></InpuPassword>
            <SpanIconPassword
              onClick={() => {
                setShowPass(!showPass);
              }}
            >
              <ImgIconPassword theme={{ showPass }} />
            </SpanIconPassword>
            {reqPass && <SpanElementRequired>{reqPass}</SpanElementRequired>}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <Row>
              <Col xs={1} md={1}>
                <CheckBoxRememberPass
                  type={"checkbox"}
                  onClick={() => {
                    setRememberPass(!rememberPass);
                  }}
                />
              </Col>
              <ColBoxRememberPass xs={11} md={11}>
                <SpanBoxRememberPass>
                  {" "}
                  {getLangLabel("REMEMBER PASSWORD")}
                </SpanBoxRememberPass>
              </ColBoxRememberPass>
            </Row>
          </div>
          <div style={{ marginBottom: "0rem" }}>
            <BtnLogin
              className="login-button"
              onClick={() => {
                evaluationInputsLogin();
              }}
              type={"submit"}
            >
              {getLangLabel("LOG-IN")}
              <LoaderSpin className={"ms-2"} show={loader} />
            </BtnLogin>
          </div>
        </form>

        <div style={{ marginBottom: "1rem" }}>
          <Row>
            <SpanForgotPass
              onClick={() => {
                handleCloseModalSignIn();
                handleOpenModalForgotPass();
              }}
            >
              {getLangLabel("FORGOT PASSWORD?")}
            </SpanForgotPass>
          </Row>
        </div>
        {/* CLIENT LOGIN OPTIONS, IN IS_B2B == true && userType==1 this section will not available 
            note: for the moment the rule IN IS_B2B == true && userType==1 will not be applied 12/10/2022 
        */}
        <OptionClientsContainer
          theme={{
            IS_B2B,
            userType,
            show: !SHOW_GOOGLE && !SHOW_FACEBOOK ? false : true,
          }}
        >
          <div style={{ marginBottom: "1.5rem" }}>
            <Row>
              <ColOr xs={5} md={5} theme={{ isMobile }}></ColOr>
              <ColOrTittle xs={2} md={2} theme={{ isMobile }}>
                O
              </ColOrTittle>
              <ColOr xs={5} md={5} theme={{ isMobile }}></ColOr>
            </Row>
          </div>
          <div style={{ marginBottom: "0.5rem" }}>
            <RowBtnLoginFb theme={{ show: SHOW_FACEBOOK }}>
              <Col>
                <FacebookLogin
                  appId={`${FACEBOOK_APP_ID}`}
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={responseFacebook}
                  render={(renderProps) => (
                    <BtnLoginFacebook
                      onClick={() => {
                        setErrorSignIn("");
                        renderProps.onClick();
                      }}
                    >
                      <ImgIconFb />
                      {getLangLabel("CONTINUE WITH FACEBOOK")}
                    </BtnLoginFacebook>
                  )}
                />
              </Col>
            </RowBtnLoginFb>
          </div>
          <div style={{ marginBottom: "2rem" }}>
            <RowBtnGoogle theme={{ show: SHOW_GOOGLE }}>
              <GoogleLogin
                clientId={`${GOOGLE_APP_ID}`}
                render={(renderProps) => (
                  <BtnLoginGoogle
                    onClick={() => {
                      setErrorSignIn("");
                      renderProps.onClick();
                    }}
                    disabled={renderProps.disabled}
                  >
                    <SpanIConGoogle>
                      <ImgIconGoogle />
                    </SpanIConGoogle>
                    {getLangLabel("CONTINUE WITH GOOGLE")}
                  </BtnLoginGoogle>
                )}
                buttonText="Login"
                onSuccess={(e) => {
                  responseGoogleSuccess(e);
                }}
                onFailure={
                  (/* e */) => {
                    responseGoogleError(/* e */);
                  }
                }
                cookiePolicy={"single_host_origin"}
              />
            </RowBtnGoogle>
          </div>
          <div className={"text-center"} style={{ marginBottom: "2rem" }}>
            <SpanMessage theme={{ isMobile }}>
              {/* Dont have a Travelwithsky Account?{" "} */}
              {getLangLabel("DON'T HAVE AN IJF ACCOUNT?")}{" "}
              <SpanSignUp
                onClick={() => {
                  handleCloseModalSignIn();
                  handleOpenModalSignUp();
                }}
                className="span-sign-up"
              >
                {getLangLabel("SIGN-UP")}
              </SpanSignUp>
            </SpanMessage>
          </div>
        </OptionClientsContainer>
      </ModalBody>
    </ModalCustom>
  );
};
