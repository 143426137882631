import { create } from "zustand";

interface useMyVacationReservationStoreProps {
  myReservation: myVacationReservationResponse | null;
  setMyReservation: (value: myVacationReservationResponse | null) => void;
  getMyReservation: () => myVacationReservationResponse | null;
}

export const useMyVacationReservationStore =
  create<useMyVacationReservationStoreProps>((set, get) => ({
    myReservation: null,
    setMyReservation: (value) => {
      set({ myReservation: value });
    },
    getMyReservation: () => {
      const { myReservation } = get();
      return myReservation;
    },
  }));
