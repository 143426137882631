import { getCookie } from "cookies-next";
import { useEffect } from "react";
import { shallow } from "zustand/shallow";
import { usePersonalWallet } from "@store/WalletPersonalStore";
import { useLayoutStore } from "@store/layoutStore";
import { getVacationReservation } from "@lib/api/reservations";
import { useMyVacationReservationStore } from "@store/useMyVacationReservationStore";

async function myReservationAPI(token: string) {
  const json = {
    token: token,
  };
  const response = await getVacationReservation(json);
  return response;
}

export const useMyReservation = () => {
  const { executeMyVacations, setExecuteMyVacations } = usePersonalWallet();
  const { setMyReservation } = useMyVacationReservationStore();
  const { isLoadedExternalConfig } = useLayoutStore(
    (state) => ({
      isLoadedExternalConfig: state?.isLoadedExternalConfig,
    }),
    shallow
  );

  useEffect(() => {
    async function executeMyReservationAPI(token: string) {
      const response = await myReservationAPI(token);
      setMyReservation(response);
    }
    const token: string = String(getCookie("myReservationToken") || "");
    if (token && isLoadedExternalConfig && executeMyVacations) {
      setExecuteMyVacations(false);
      executeMyReservationAPI(token);
    }
  }, [
    isLoadedExternalConfig,
    executeMyVacations,
    setExecuteMyVacations,
    setMyReservation,
  ]);
};

export const myReservationReedirectionProfile = (
  myReservation: myVacationReservationResponse | null,
  userDetailsTab?: string
) => {
  let reeDirect: boolean = false;

  /* ENDLESS REEDIRECTION */
  if (
    Array.isArray(myReservation?.reservations) &&
    Number(myReservation?.reservations?.length || 0) > 0
  ) {
    const endLessProfile: reservationsResponseType | undefined =
      myReservation?.reservations[0];
    if (endLessProfile && endLessProfile?.el_profile_url) {
      if (userDetailsTab && userDetailsTab != "profile") {
        reeDirect = false;
      } else {
        reeDirect = true;
        window.open(String(endLessProfile?.el_profile_url || ""), "_ blank");
      }
    }
  }

  return reeDirect;
};
