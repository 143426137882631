import CloseImg from "../../../../public/images/header/Close.svg";
import { useCurrencyLangInfo, useWhiteLabelLan } from "@hooks/useLayout";
import {
  BtnSelected,
  Circle,
  CloseM,
  CloseModall,
  ColItem,
  ModalBody,
  ModalCustom,
  ModalHeader,
  ModalTitle,
  RowBody,
  Span,
  SpanCode,
} from "./styles";
import { Col, Row } from "react-bootstrap";
import { getCookie } from "cookies-next";
import { getSymb, setCurrencyCookies } from "@helpers/helper";
import { useAuth } from "@contexts/AuthContext";
import { USER_TYPE } from "@helpers/config";
import { updatePrefCurrLang } from "@lib/api/profile/updatePrefCurrLang";
import { useRouter } from "next/router";

type ModalCurrencyProps = {
  show: boolean;
  handleClose: () => void;
};

const ModalCurrency: React.FC<ModalCurrencyProps> = ({ show, handleClose }) => {
  const { getLangLabel } = useWhiteLabelLan();
  const { currencies } = useCurrencyLangInfo();
  const { currentUser } = useAuth();
  const router = useRouter();

  const updateCurrency = async (selectedCurrency: CurrencyDetailType) => {
    setCurrencyCookies(
      selectedCurrency.id,
      selectedCurrency.name,
      selectedCurrency.code
    );
    if (currentUser) {
      const objUpdate = {
        usr_type: USER_TYPE || "2",
        preferred_language: getCookie("lan_id"),
        preferred_currency: String(selectedCurrency.id),
      };

      updatePrefCurrLang(objUpdate).then(() => router.reload());
    } else {
      handleClose();
      router.reload();
    }
  };

  const getImgSelected = (code?: string | null) => {
    if (code) {
      const find = currencies.find((f) => f.code == code);
      if (find) {
        return find.flag;
      }
    }

    return null;
  };

  const isSelected = (code?: string | null) => {
    if (code) {
      if (code == getCookie("currency_code")) {
        return true;
      }
    }

    return false;
  };

  return (
    <ModalCustom
      show={show}
      onHide={handleClose}
      size={"lg"}
      className="modalcolors"
    >
      <ModalHeader>
        <ModalTitle>{getLangLabel("Suggested Currency")}</ModalTitle>
        <CloseM>
          <CloseModall
            onClick={() => handleClose()}
            src={CloseImg}
            alt="CloseImg"
            width={16}
            height={16}
          ></CloseModall>
        </CloseM>
      </ModalHeader>
      <ModalBody>
        <Row>
          <ColItem lg={4} xs="12">
            <BtnSelected
              variant="light"
              theme={{
                isSelected: true,
                flag: getImgSelected(String(getCookie("currency_code"))),
              }}
            >
              <Circle
                src={"/images/general/circle.svg"}
                width={"7px"}
                height={"7px"}
              />
              <Span>{getCookie("currency_name")}</Span>
              <SpanCode>
                {getCookie("currency_code")}-
                {getSymb(String(getCookie("currency_code")))}
              </SpanCode>
            </BtnSelected>
          </ColItem>
        </Row>
        <RowBody>
          <Col>{getLangLabel("all currencies")}</Col>
        </RowBody>
        <Row>
          {currencies.map((c, i) => {
            return (
              <ColItem lg={4} xs="12" key={`col-item-cur-${i}-${c.name}`}>
                <BtnSelected
                  variant="light"
                  theme={{
                    isSelected: isSelected(c.code),
                    flag: c.flag,
                  }}
                  onClick={async () => {
                    await updateCurrency(c);
                  }}
                >
                  {isSelected(c.code) && (
                    <Circle
                      src={"/images/general/circle.svg"}
                      width={"7px"}
                      height={"7px"}
                    />
                  )}
                  <Span>{c.name}</Span>
                  <SpanCode>
                    {c.code}-{getSymb(c.code)}
                  </SpanCode>
                </BtnSelected>
              </ColItem>
            );
          })}
        </Row>
      </ModalBody>
    </ModalCustom>
  );
};

export default ModalCurrency;
