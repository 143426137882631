/* eslint-disable @next/next/no-img-element */

import ModalCurrency from "../Header/ModalCurrency";
import { useCurrencyLangInfo } from "@hooks/useLayout";
import { configStore } from "@store/configStore";

export default function Currencies() {
  const { currencies } = useCurrencyLangInfo();
  const { setShowCurrencyModal } = configStore();
  const { showCurrencyModal } = configStore((state) => ({
    showCurrencyModal: state.showCurrencyModal,
  }));
  return (
    <div className=" tw-w-full">
      {currencies.length > 0 && (
        <ModalCurrency
          show={showCurrencyModal}
          handleClose={() => setShowCurrencyModal(false)}
        />
      )}
    </div>
  );
}
