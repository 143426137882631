import { IS_PRESTIGE, IS_SPORTSNTRAVEL, IS_VOYVIO } from "@helpers/config";
import { css } from "styled-components";

export const ScrollbarStyle = css`
  /* background-color: white; */
  /* Works on Firefox */
  scrollbar-width: 20px;
  scrollbar-color: #686e9e #fff;

  /* Works on Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #686e9e;
    border-radius: 10px;
    border: 3px solid #e6ecf8;
  }
`;
export const SportsnTravelGreen = css`
  background-color: var(--sportsnravel-green) !important;
  border-color: var(--sportsnravel-green) !important;
`;

export const VoyvioSvgOrange = css`
  filter: var(--voyvio-orange-svg);
`;

export const SportsTravelSvgGreen = css`
  filter: var(--sportsnravel-green-svg);
  color: var(--sportsnravel-green-svg);
`;
export const GreenDealSvgGreen = css`
  color: var(--greendeal-inspira-green);
`;
export const VoyvioColorOrange = css`
  color: var(--voyvio-orange);
`;
export const VoyvioBackgroundOrange = css`
  background-color: var(--voyvio-orange);
`;
export const VoyvioBorderColorOrange = css`
  border-color: var(--voyvio-orange);
`;

export const VoyvioBackgroundImageLocation = css`
  background-image: url("/images/Voyvio/Main/icons/location-orange.png");
`;

export const VoyvioBackgroundImageCalendar = css`
  background-image: url("/images/Voyvio/Main/icons/calendar1-orange.png");
`;

export const VoivioBackgroundImageUserIcon = css`
  background-image: url("/images/Voyvio/Main/icons/user-orange.png");
`;

export const VoivioContentUserIcon = css`
  content: url("/images/Voyvio/Main/icons/user-orange.png");
`;

export const SportsBackgroundImageLocation = css`
  background-image: url("/images/Voyvio/Main/icons/green-location.svg");
`;

export const PrestigeBackgroundImageLocation = css`
  background-image: url("/images/Voyvio/Main/icons/location-red.svg");
`;

export const SportsBackgroundImageCalendar = css`
  background-image: url("/images/Voyvio/Main/icons/green-calendar.svg");
`;
export const PrestigeBackgroundImageCalendar = css`
  background-image: url("/images/Voyvio/Main/icons/calendar-red.svg");
`;
export const SportsBackgroundImageUserIcon = css`
  background-image: url("/images/Voyvio/Main/icons/green-user.svg");
`;
export const PrestigeBackgroundImageUserIcon = css`
  background-image: url("/images/Voyvio/Main/icons/user-red.svg");
`;
export const SearchIconRed = css`
  background-image: url("/images/Voyvio/Main/icons/search-red.svg");
`;
export const MapViewOffBlack = css`
  background-image: url("/images/Voyvio/Main/icons/MapViewOff-black.svg");
`;
export const ListViewOffBlack = css`
  background-image: url("/images/Voyvio/Main/icons/ListViewoff-black.svg");
`;

export const ColorGreenDeal = css`
  ${() => {
    return GreenDealSvgGreen;
  }}
`;

export const ColorDetectBySite = css`
  ${() => {
    if (IS_VOYVIO) {
      return VoyvioColorOrange;
    }
    if (IS_SPORTSNTRAVEL) {
      return SportsTravelSvgGreen;
    }
    return null;
  }}
`;

export const BorderColorDetectBySite = css`
  ${() => {
    if (IS_VOYVIO) {
      return VoyvioBorderColorOrange;
    }
    return null;
  }}
`;

export const FilterDetectBySite = css`
  ${() => {
    if (IS_VOYVIO) {
      return VoyvioSvgOrange;
    }
    if (IS_SPORTSNTRAVEL) {
      return SportsTravelSvgGreen;
    }
    return null;
  }}
`;

export const FilterDetectGreenDeal = css`
  ${() => {
    return GreenDealSvgGreen;
  }}
`;

export const FilterDetectBySiteSnT = css`
  ${() => {
    if (IS_SPORTSNTRAVEL) {
      return SportsnTravelGreen;
    }
    return null;
  }}
`;

export const BackgroundColorDetectBySite = css`
  ${() => {
    if (IS_VOYVIO) {
      return VoyvioBackgroundOrange;
    }
    return null;
  }}
`;

export const BackgroundInputLocationDetectBySite = css`
  ${() => {
    // if (IS_VOYVIO) {
    //   return VoyvioBackgroundImageLocation;
    // }
    // if (IS_SPORTSNTRAVEL) {
    //   return SportsBackgroundImageLocation;
    // }
    // if (IS_PRESTIGE) {
    //   return PrestigeBackgroundImageLocation;
    // }
    return null;
  }}
`;

export const BackgroundInputCalendar = css`
  ${() => {
    // if (IS_VOYVIO) {
    //   return VoyvioBackgroundImageCalendar;
    // }
    // if (IS_SPORTSNTRAVEL) {
    //   return SportsBackgroundImageCalendar;
    // }
    // if (IS_PRESTIGE) {
    //   return PrestigeBackgroundImageCalendar;
    // }
    return null;
  }}
`;

export const BackgroundInputUserIcon = css`
  ${() => {
    // if (IS_VOYVIO) {
    //   return VoivioBackgroundImageUserIcon;
    // }
    // if (IS_SPORTSNTRAVEL) {
    //   return SportsBackgroundImageUserIcon;
    // }
    // if (IS_PRESTIGE) {
    //   return PrestigeBackgroundImageUserIcon;
    // }
    return null;
  }}
`;

export const ContentImageVoivioUserIcon = css`
  ${() => {
    if (IS_VOYVIO) {
      return VoivioContentUserIcon;
    }
    return null;
  }}
`;
export const SearchIconRedCss = css`
  ${() => {
    if (IS_PRESTIGE) {
      return SearchIconRed;
    }
    return null;
  }}
`;
export const MapViewOffBlackCss = css`
  ${() => {
    if (IS_PRESTIGE) {
      return MapViewOffBlack;
    }
    return null;
  }}
`;
export const ListViewOffBlackCss = css`
  ${() => {
    if (IS_PRESTIGE) {
      return ListViewOffBlack;
    }
    return null;
  }}
`;

export const ColorBackGroundNumSelector = css`
  background-color: ${({ theme }: any) => {
    const type = theme.type;
    let color = "";
    switch (type) {
      case "tournament":
        color = "var(--inspira-purple2)";

        break;
      case "holiday":
        color = "#FF545B";

        break;
      case "holidayDest":
        color = "#04D94F";

        break;

      default:
        color = "var(--inspira-purple2)";
        break;
    }
    return color;
  }};
`;

export const ColorSelector = css`
  color: ${({ theme }: any) => {
    const type = theme.type;
    let color = "";
    switch (type) {
      case "tournament":
        color = "var(--inspira-purple2)";

        break;
      case "holiday":
        color = "#FF545B";

        break;
      case "holidayDest":
        color = "#04D94F";
        break;
      case "future":
        color = "var(--bs-gray-800)";
        break;

      default:
        color = "var(--bs-gray-500)";
        break;
    }
    return color;
  }} !important;
`;
