import { create } from "zustand";
interface membershipUpgradeState {
  planStatus?: boolean;
  membershipStatus?: boolean;
  setMembershipStatus: (value: boolean) => void;
  setPlanStatus: (value: boolean) => void;
  plansAvailable?: PlansApiDataType[] | null;
  setPlansAvailable: (value: PlansApiDataType[] | null) => void;
}

export const useMembershipUpgradeStore = create<membershipUpgradeState>(
  (set) => ({
    planStatus: false,
    membershipStatus: false,
    plansAvailable: null,
    setMembershipStatus: (value: boolean) => set({ membershipStatus: value }),
    setPlanStatus: (value: boolean) => set({ planStatus: value }),
    setPlansAvailable: (value: PlansApiDataType[] | null) =>
      set({ plansAvailable: value }),
  })
);
