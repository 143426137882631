import * as crypto from "crypto";
import axios from "axios";
import {
  DAILY_COMPANY_ID,
  DAILY_REDIRECT_PATH,
  DAILY_USER_ORG_KEY,
  DAILY_USER_PROGRAM_KEY,
} from "@helpers/config";

const encryptStringWithSHA256 = (str: string): string => {
  const sha256 = crypto.createHash("sha256");
  sha256.update(str);
  return sha256.digest("hex");
};

const encryptString = (cvt: string) => {
  //concatenar string a encriptar
  const organization_key = DAILY_USER_PROGRAM_KEY;
  const program_key = DAILY_USER_PROGRAM_KEY;
  const cvt_key = cvt;
  const string_to_encrypt =
    organization_key + "-" + program_key + "-" + cvt_key;
  console.log("--- encrypt001", organization_key, program_key, cvt_key);
  console.log("***********string to encrypt", string_to_encrypt);
  const encryptedString = encryptStringWithSHA256(string_to_encrypt);
  return encryptedString;
};

const checkExistMember = async (memberInfo: {
  email: string;
  userId: string;
  memberid: string;
}) => {
  const response: any = await axios.post("/api/savings/getMember", {
    email: memberInfo.email,
    userId: memberInfo.userId,
    memberid: memberInfo.memberid, //"PERKS103492",
  });

  return response?.data?.code == 200;
};

const saveMember = async (memberInfo: {
  email: string;
  userId: string;
  memberid: string;
}) => {
  const addMember: { data: { status: string } } = await axios.post(
    "/api/savings/setMember",
    memberInfo
  );
  return addMember?.data?.status == "OK";
};

const dailySaveUser = async (currentUser?: UserModelType) => {
  const first_name = currentUser?.first_Name || "";
  const last_name_BD = currentUser?.last_name || "";
  const user_email_BD = currentUser?.email;
  const user_id = currentUser?.userId;
  const user_cvt_BD = `${DAILY_COMPANY_ID}${user_id}`;
  const importData: SavingsRequestPropsType = {
    import: {
      members: [
        {
          organization_customer_identifier: DAILY_USER_ORG_KEY ?? "",
          program_customer_identifier: DAILY_USER_PROGRAM_KEY ?? "",
          member_status: "OPEN",
          first_name: first_name,
          last_name: last_name_BD,
          email_address: String(user_email_BD),
          member_customer_identifier: `${user_cvt_BD}`,
        },
      ],
    },
  };

  const response = await axios.post(`/api/savings/savingsCode`, {
    reqSaving: importData,
  });
  console.log("importData on save appi", importData);
  console.log("response on save appi", response);
  return response?.data?.data[0]?.valid_members_count == 1;
};

const redirectUrl = (encrypt: string) => {
  console.log("redirectUrl DAILY_REDIRECT_PATH", DAILY_REDIRECT_PATH, encrypt);
  return `${DAILY_REDIRECT_PATH}/director?cvt=${encrypt}`;
};

const dailyMainFlow = async (currentUser?: UserModelType) => {
  if (currentUser) {
    const cvt = `${DAILY_COMPANY_ID}${currentUser?.userId}`;
    const member = {
      email: currentUser?.email ?? "",
      userId: currentUser?.userId ?? "",
      memberid: cvt,
    };
    console.log("dailyMainFlow cvt", cvt, member);
    const existMember = await checkExistMember(member);
    console.log("dailyMainFlow existMember", existMember);
    if (existMember) {
      const url = redirectUrl(encryptString(cvt));
      console.log(
        "dailyMainFlow ook existMember cvt",
        encryptString(cvt),
        cvt,
        url
      );
      return { isNew: false, pathRedirect: url };
    }

    const dailyNewUser = await dailySaveUser(currentUser);
    console.log("dailyMainFlow not existMember", dailyNewUser);
    if (dailyNewUser) {
      const isSaveLocalMember = await saveMember(member);
      console.log("dailyMainFlow save ok , not existMember", dailyNewUser);
      console.log(
        "dailyMainFlow isSaveLocalMember, not existMember",
        isSaveLocalMember
      );

      if (isSaveLocalMember) {
        const url = redirectUrl(encryptString(cvt));
        console.log(
          "dailyMainFlow isSaveLocalMember, not existMember, cvt",
          url
        );
        return { isNew: true, pathRedirect: url };
      }

      return { isNew: true, pathRedirect: "" };
    }
  } else {
    return { isNew: false, pathRedirect: "action:showSignin" };
  }
};

const addNewDailyMember = async (currentUser?: UserModelType) => {
  if (currentUser) {
    const cvt = `${DAILY_COMPANY_ID}${currentUser?.userId}`;
    const member = {
      email: currentUser?.email ?? "",
      userId: currentUser?.userId ?? "",
      memberid: cvt,
    };
    console.log("addNewDailyMember layout-- member", member);

    const existMember = await checkExistMember(member);
    console.log("addNewDailyMember layout--existmember", existMember);
    if (!existMember) {
      const dailyNewUser = await dailySaveUser(currentUser);
      console.log("addNewDailyMemberlayout-- si existmember", dailyNewUser);
      console.log("addNewDailyMember not existMember", dailyNewUser);
      if (dailyNewUser) {
        const isSaveLocalMember = await saveMember(member);
        console.log(
          "addNewDailyMember save ok , not existMember",
          dailyNewUser
        );
        console.log(
          "addNewDailyMember isSaveLocalMember, not existMember",
          isSaveLocalMember
        );

        if (isSaveLocalMember) {
          console.log("addNewDailyMember save member");
          return "addNewDailyMember save member";
        }
        console.log("addNewDailyMember no Save member");
        return "addNewDailyMember no Save member";
      }
      console.log("addNewDailyMember no save dailyNewMember");
      return "addNewDailyMember no save dailyNewMember";
    }
    console.log("exist member");
    return "exist member";
  } else {
    console.log("addNewDailyMember without login");
    return "addNewDailyMember without login";
  }
};
const DailyService = {
  encryptString,
  checkExistMember,
  saveMember,
  dailySaveUser,
  redirectUrl,
  dailyMainFlow,
  addNewDailyMember,
};

export default DailyService;
