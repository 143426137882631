import { useEffect, useState } from "react";
import { Overlay } from "./Overlay";
import { InputAutocomplete, InputAutocompleteWrapper, XSpan } from "./styles";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { checkCookies, getCookie } from "cookies-next";
import { useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";
import { preventExecuteCodeHTMLJS } from "@helpers/helper";

type AutoCompletePhoneProps = {
  onChanges: (optionSelected: CountryListType) => void;
  value?: number;
  origin?: "booking_history";
};

export const AutoCompletePhone: React.FC<AutoCompletePhoneProps> = ({
  onChanges,
  value,
  origin,
}) => {
  const { getLangLabel } = useWhiteLabelLan();
  const { options } = useLayoutStore(
    (state) => ({
      options: state.countryList,
    }),
    shallow
  );
  const [showOverlay, setShowOverlay] = useState(false);
  const [onFocus, setOnFocus] = useState(false);
  const [onExecuteClear, setOnExecuteClear] = useState(false);
  const [valueText, setValueText] = useState("");
  const [valueIcon, setValueIcon] = useState("");

  const handlerOpenOverlay = () => {
    setShowOverlay(true);
  };

  const handlerCloseOverlay = () => {
    if (showOverlay) {
      setShowOverlay(false);
    }
  };

  function filterArray() {
    let arrNew = options?.filter((item) => {
      if (valueText && item?.countryName) {
        return `${item.countryName} ${item.countryCode} ${item.isdCode}`
          .toLowerCase()
          .includes(valueText.toLowerCase());
      }
      return !!item?.countryId;
    });
    return arrNew;
  }

  const handlerOnClickSuggest = async (val: string, iconB: string) => {
    setValueText(val);
    setValueIcon(iconB);
    handlerCloseOverlay();
  };

  const getBanUrl = (countryId: number) => {
    const countryInfoCookie = checkCookies("countryInfo")
      ? JSON.parse(String(getCookie("countryInfo")))
      : null;
    const positionCountryId = countryInfoCookie?.geoplugin_countryCode || null;

    let countryPostionID = positionCountryId;
    const iconInfo = options.find((f) => f.countryCode == countryPostionID);

    if (iconInfo) {
      return iconInfo?.countryFlag;
    } else {
      const icon = options.find((f) => f.countryId == countryId);

      if (icon) {
        return icon?.countryFlag;
      }
    }

    return "";
  };
  const getBanText = (countryId: number) => {
    const countryInfoCookie = checkCookies("countryInfo")
      ? JSON.parse(String(getCookie("countryInfo")))
      : null;
    const positionCountryId = countryInfoCookie?.geoplugin_countryCode || null;

    let countryPostionID = positionCountryId;

    const countryInfo = options.find((f) => f.countryCode == countryPostionID);
    if (countryInfo) {
      return `${countryInfo?.countryCode} ${countryInfo?.isdCode}`;
    } else {
      const countryData = options.find((f) => f.countryId == countryId);
      if (countryData) {
        return `${countryData?.countryCode} ${countryData?.isdCode}`;
      }
    }

    return "";
  };

  const getDefBanUrl = (type?: string) => {
    const defCountryCode = checkCookies("userCountryCode")
      ? getCookie("userCountryCode")
      : "US";
    const found = options.find((f) => f.countryCode == String(defCountryCode));

    if (type == "flag") {
      return found ? found?.countryFlag : "";
    }

    return found ? `${found?.countryCode} ${found?.isdCode}` : "";
  };
  useEffect(() => {
    if (Number(value) > 0) {
      const title = getBanText(Number(value));
      const icon = getBanUrl(Number(value));
      setValueText(title);
      setValueIcon(icon || "");
    } else {
      const title = getDefBanUrl("titlecode");
      const icon = getDefBanUrl("flag");
      console.log("onClearSetNEwVal1");
      setValueText(title);
      setValueIcon(icon);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  useEffect(() => {
    const currentText = getBanText(Number(valueText));
    if (
      (!valueText || !currentText) &&
      !showOverlay &&
      value &&
      !onExecuteClear
    ) {
      const title = getBanText(Number(value));
      const icon = getBanUrl(Number(value));
      setValueText(title);
      setValueIcon(icon || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueText, showOverlay, value]);
  return (
    <InputAutocompleteWrapper>
      <InputAutocomplete
        type="text"
        theme={{ icon: valueIcon }}
        className="form-control checkoutbdquestions-act"
        placeholder={getLangLabel("CODE")}
        value={valueText}
        onChange={(e: any) => {
          const text = preventExecuteCodeHTMLJS(String(e.target.value || ""));
          setValueText(text);
        }}
        onClick={() => {
          setTimeout(() => {
            handlerOpenOverlay();
            valueText !== "" ? setOnFocus(true) : setOnFocus(false);
          }, 200);
        }}
        onBlur={() => {
          setTimeout(() => {
            setOnFocus(false);
          }, 2000);
        }}
        required={true}
      />
      <XSpan
        style={{ display: onFocus ? "inline-flex" : "none" }}
        onClick={() => {
          setOnExecuteClear(true);
          setValueText("");
          setValueIcon("");
        }}
      />
      <Overlay
        show={showOverlay}
        handlerClose={handlerCloseOverlay}
        options={filterArray()}
        handlerOnClickSuggest={handlerOnClickSuggest}
        onChanges={onChanges}
        title={valueText}
        origin={origin}
      />
    </InputAutocompleteWrapper>
  );
};
