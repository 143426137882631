type LoaderSpinProps = {
  show: boolean;
  className?: string;
};

export const LoaderSpin: React.FC<LoaderSpinProps> = ({
  show = false,
  className,
}) => {
  if (show) {
    return (
      <span
        className={`spinner-border spinner-border-sm ${className}`}
        role="status"
        aria-hidden="true"
      ></span>
    );
  }
  return <></>;
};
