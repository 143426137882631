import CloseImg from "../../../../public/images/header/Close.svg";
import { useCurrencyLangInfo, useWhiteLabelLan } from "@hooks/useLayout";
import {
  BtnSelected,
  CloseM,
  CloseModall,
  ColItem,
  ModalBody,
  ModalCustom,
  ModalHeader,
  ModalTitle,
  RowBody,
  Span,
} from "./styles";
import { Col, Row } from "react-bootstrap";
import { getCookie } from "cookies-next";
import { setLangCookies } from "@helpers/helper";
import { useAuth } from "@contexts/AuthContext";
import { USER_TYPE } from "@helpers/config";
import { updatePrefCurrLang } from "@lib/api/profile/updatePrefCurrLang";
import { useRouter } from "next/router";

type ModalLanguageProps = {
  show: boolean;
  handleClose: () => void;
};

const ModalLanguage: React.FC<ModalLanguageProps> = ({ show, handleClose }) => {
  const { getLangLabel } = useWhiteLabelLan();
  const { languages } = useCurrencyLangInfo();
  const { currentUser } = useAuth();
  const router = useRouter();

  const updateLanguage = async (newlanguague: LangDetailTypeApi) => {
    setLangCookies(newlanguague.name, newlanguague.code, newlanguague.id);
    if (currentUser) {
      const objUpdate = {
        usr_type: USER_TYPE || "2",
        preferred_language: newlanguague.id,
        preferred_currency: getCookie("currency_id"),
      };

      updatePrefCurrLang(objUpdate).then(() => router.reload());
    } else {
      handleClose();
      router.reload();
    }
  };

  const getImgSelected = (code?: string | null) => {
    if (code) {
      const find = languages.find((f) => f.code == code);
      if (find) {
        return find.img;
      }
    }

    return null;
  };
  return (
    <ModalCustom
      show={show}
      onHide={handleClose}
      size={"lg"}
      className="modalcolors"
    >
      <ModalHeader>
        <ModalTitle>{getLangLabel("Suggested LANGUAGES")}</ModalTitle>
        <CloseM>
          <CloseModall
            onClick={() => handleClose()}
            src={CloseImg}
            alt="CloseImg"
            width={16}
            height={16}
          ></CloseModall>
        </CloseM>
      </ModalHeader>
      <ModalBody>
        <Row>
          <ColItem lg="4" xs="12">
            <BtnSelected
              variant="light"
              theme={{
                isSelected: true,
                flag: getImgSelected(String(getCookie("lan_code"))),
              }}
            >
              <Span>{getCookie("lan_name")}</Span>
            </BtnSelected>
          </ColItem>
        </Row>
        <RowBody>
          <Col>{getLangLabel("all languages")}</Col>
        </RowBody>
        <Row>
          {languages.map((l, i) => {
            return (
              <ColItem
                xs="12"
                sm="6"
                md="4"
                key={`col-item-lang-${i}-${l.name}`}
              >
                <BtnSelected
                  variant="light"
                  theme={{
                    isSelected: l.code == getCookie("lan_code"),
                    flag: l.img,
                  }}
                  onClick={async () => {
                    await updateLanguage(l);
                  }}
                >
                  <Span>{l.name}</Span>
                </BtnSelected>
              </ColItem>
            );
          })}
        </Row>
      </ModalBody>
    </ModalCustom>
  );
};

export default ModalLanguage;
