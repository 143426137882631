/* eslint-disable @next/next/no-img-element */
import { IMAGE_PATH, LOGO_URL } from "@helpers/config";
import { useLayoutStore } from "@store/layoutStore";
import { getCookie } from "cookies-next";
import Link from "next/link";
import { shallow } from "zustand/shallow";
import Menu from "./Menu";
import { classNames } from "@helpers/helper";
import { useWhiteLabelLan } from "@hooks/useLayout";
import UserMenu from "./UserMenu";
type NavbarProps = {
  showUserMenu?: boolean;
  showExploreButton?: boolean;
};
export default function Navbar({
  showUserMenu = true,
  showExploreButton = true,
}: NavbarProps) {
  const { getLangLabel } = useWhiteLabelLan();
  const { logoUrl, upgradeUrl } = useLayoutStore(
    (state) => ({
      logoUrl: state.externalConfig?.logoUrl || "",
      upgradeUrl: state.externalConfig?.upgradeOptions?.url || "",
    }),
    shallow
  );

  const getLogoUrl = (externalImage: string) => {
    if (externalImage) {
      return externalImage;
    }
    if (LOGO_URL) return LOGO_URL;
    if (getCookie("logo")) return `${IMAGE_PATH}${getCookie("logo")}`;
    return "";
  };

  const logo = getLogoUrl(logoUrl);
  return (
    <section>
      <nav className="tw-flex tw-flex-row tw-justify-between ">
        <div className="tw-container tw-max-w-[300px] ">
          <Link href="/">
            <a>
              {logo && (
                <img
                  className=" tw-min-w-10 tw-max-w-[20rem] tw-h-auto tw-min-h-2 tw-max-h-20 logo2 img-fluid tw-py-[5px] tw-px-[10px]"
                  src={logo}
                  alt="logo"
                />
              )}
            </a>
          </Link>
        </div>
        <div className=" tw-flex tw-flex-row tw-gap-2 lg:tw-gap-10">
          {showExploreButton && <Menu />}
          <div
            className={classNames(
              "  tw-hidden md:tw-flex tw-justify-center tw-items-center tw-align-middle",
              upgradeUrl ? "" : "!tw-hidden"
            )}
          >
            <Link href={upgradeUrl}>
              <a
                className={classNames(
                  "tw-hidden md:tw-inline-flex",
                  "hover:tw-bg-inspira-purple-100 tw-bg-inspira-purple-600 tw-text-white tw-min-w-[7rem] tw-border tw-border-gray-400",
                  "focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-300 tw-font-medium tw-rounded-full tw-text-sm tw-px-1 tw-py-1",
                  "tw-text-center  tw-items-center tw-justify-center tw-align-middle tw-no-underline"
                )}
              >
                {getLangLabel("Upgrade Now")}
              </a>
            </Link>
          </div>
          {showUserMenu && (
            <div className=" tw-flex tw-justify-center tw-items-center tw-align-middle">
              <UserMenu />
            </div>
          )}
        </div>
      </nav>
    </section>
  );
}
