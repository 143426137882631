import { useMain } from "@contexts/mainContext";
import { compareValues } from "@helpers/arrayFuntions";
import {
  getContriesList,
  getProducts,
  insertProducts,
  orderCountryListByCountryCode,
  setCountriesList,
  setCurrencyCookies,
  setCurrencyCookiesLogout,
  setLangCookies,
  setLangCookiesLogout,
} from "@helpers/helper";
import {
  checkCookies,
  getCookie,
  removeCookies,
  setCookies,
} from "cookies-next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useLanguageStore } from "store/languageStore";
import { shallow } from "zustand/shallow";
import { useLayoutStore } from "@store/layoutStore";
import { getCurrencyRate } from "@lib/api/general/getCurrencyInfoApi";
import { useCurrencyLangConfigStore } from "@store/useCurrencyLangConfigStore";
import { getConfig, getUserProfileAPI } from "@lib/api/layout";
import { getCheckLogin } from "@lib/api/auth/getCheckLogin";
import { useUserProfile } from "@contexts/UserContext";
// import PlanService from "@services/checkPlanCero.service";
import { useAuth } from "@contexts/AuthContext";
import { DAILY_CONFIG } from "@helpers/config";
import DailyService from "@services/daily.service";
import { getIp } from "@lib/api/getIp";
import { getGeolocationByIpApi } from "@lib/api/general/getGeolocationByIpApi";
import { getCssPreAndPostLogin } from "@lib/api/getwhitelabelcss";
import { getNationalitiesApi } from "@lib/api/general/getNationalitiesApi";
import { NATIONALITY_KEY } from "@helpers/AuthHelpers";
import { getCountryDataAPI } from "@lib/api/general/getCountry";
import { useMembershipUpgradeStore } from "@store/membershipUpgradeStore";
import { WITH_TAX } from "@data/consts";
import { CURRENCY_CODE_BASE } from "@store/walletUserStore";
import { uuidv4 } from "@lib/helpers";
import { getCountryById } from "@helpers/country";
import { COOKIE_BOOKING_OTHER_ORG } from "@data/menuItems";
// import { useWeeksCheckoutStore } from "@store/weeksCheckoutStore";

// falta lenguages
export const useCurrencyLangInfo = (data?: CurrenciesDetailsApiType) => {
  const router = useRouter();

  const {
    setCurrencies,
    setCurrenciesDefault,
    setLanguages,
    setLanguageDefault,
  } = useCurrencyLangConfigStore();
  const {
    currencies,
    currenciesDefault,
    languageDefault,
    languages,
    currentCurrency,
    currentLanguage,
  } = useCurrencyLangConfigStore(
    (state) => ({
      currencies: state.currencies,
      currenciesDefault: state.currenciesDefault,
      languageDefault: state.languageDefault,
      languages: state.languages,
      currentCurrency: state.currentCurrency,
      currentLanguage: state.currentLanguage,
    }),
    shallow
  );

  try {
    useEffect(() => {
      if (data) {
        let { lang } = router.query;
        const cookieLang = checkCookies("lan_code")
          ? String(getCookie("lan_code"))
          : "";

        const cookieCurrencyCode = checkCookies("currency_code")
          ? String(getCookie("currency_code"))
          : "";

        lang = lang ? lang : cookieLang;

        if (data.currencyDetails) {
          setCurrencies(data.currencyDetails);
        }

        if (data.languageDetails) {
          setLanguages(data.languageDetails);
        }

        if (checkCookies("ssoCurrency")) {
          const currencyCode = getCookie("ssoCurrency") || "";
          let apiCurrencies = data?.currencyDetails;
          if (apiCurrencies && apiCurrencies?.length > 0) {
            const findCurrencyUser = apiCurrencies.find(
              (f) => f.code == currencyCode
            );

            if (findCurrencyUser) {
              setCurrencyCookies(
                findCurrencyUser.id,
                findCurrencyUser.name,
                findCurrencyUser.code
              );

              removeCookies("ssoCurrency");
            }
          }
        } else {
          if (data.defaultLanguage) {
            // si no existe ya algun lenguage se setea el ppr default
            const defaultLanguege = data.defaultLanguage;
            if (defaultLanguege) {
              setLanguageDefault(defaultLanguege);

              setLangCookiesLogout(
                defaultLanguege.name,
                defaultLanguege.code,
                defaultLanguege.id
              );
              if (!lang) {
                setLangCookies(
                  defaultLanguege.name,
                  defaultLanguege.code,
                  defaultLanguege.id
                );
              }
            } else {
              setLangCookies("English", "en-US", 1);
            }
          }

          if (lang) {
            const dataLang = data.languageDetails;
            const existLang = dataLang?.find((f) => f.code == lang);

            const defaultLanguege = data.defaultLanguage;

            if (existLang) {
              setLangCookies(existLang.name, existLang.code, existLang.id);
              setLanguageDefault(defaultLanguege);
            } else {
              setLanguageDefault(defaultLanguege);
              setLangCookies(
                defaultLanguege.name,
                defaultLanguege.code,
                defaultLanguege.id
              );
            }
          }
        }

        if (checkCookies("ssoLanguage")) {
          const language = getCookie("ssoLanguage") || "";
          let apiLang = data?.languageDetails;

          if (apiLang && apiLang?.length > 0) {
            const findLangUser = apiLang.find((f) => f.code == language);

            if (findLangUser) {
              setLangCookies(
                findLangUser.name,
                findLangUser.code,
                findLangUser.id
              );
              removeCookies("ssoLanguage");
            }
          }
        } else {
          if (data.defaultCurrency) {
            const defaultCurrency = data.defaultCurrency;
            if (defaultCurrency) {
              setCurrenciesDefault(defaultCurrency);
              setCurrencyCookiesLogout(
                defaultCurrency.id,
                defaultCurrency.name,
                defaultCurrency.code
              );
              if (!cookieCurrencyCode) {
                setCurrencyCookies(
                  defaultCurrency.id,
                  defaultCurrency.name,
                  defaultCurrency.code
                );
              }
            } else {
              setCurrencyCookies(9, "United States Dollar", "USD");
            }
          }

          if (cookieCurrencyCode) {
            const dataCurrency = data.currencyDetails;
            const existCurrency = dataCurrency?.find(
              (f) => f.code == cookieCurrencyCode
            );

            const defaultCurrency = data.defaultCurrency;

            if (existCurrency) {
              setCurrencyCookies(
                existCurrency.id,
                existCurrency.name,
                existCurrency.code
              );
              setCurrenciesDefault(defaultCurrency);
            } else {
              setCurrenciesDefault(defaultCurrency);
              setCurrencyCookies(
                defaultCurrency.id,
                defaultCurrency.name,
                defaultCurrency.code
              );
            }
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, router.query]);
  } catch (error) {}

  return {
    currencies,
    currenciesDefault,
    languageDefault,
    languages,
    currentCurrency,
    currentLanguage,
  };
};

export const useWhiteLabelLan = (
  data?: WhiteLabelLangApiType[],
  showKeysLangLabel?: boolean
) => {
  const { setLanguage, setShowKeysLangLabel } = useLanguageStore();
  const { language, showKeyEmpty } = useLanguageStore(
    (state) => ({
      language: state.language,
      showKeyEmpty: state.showKeysLangLabel,
    }),
    shallow
  );

  const getLangLabel = (needle: string) => {
    if (typeof needle != "string") return "";
    const label = language?.find(
      (obj) => obj.field_name === needle.toUpperCase()
    );
    let auxKey = "";
    if (showKeyEmpty) {
      auxKey = needle;
    }
    const text = label ? String(label?.wl_lang_value) : auxKey;
    // const exists = label ? true : false;
    // return capitalize(String(text), !exists);
    return text;
  };

  useEffect(() => {
    if (data && Array.isArray(data)) {
      setLanguage(data);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (showKeysLangLabel) {
      setShowKeysLangLabel(showKeysLangLabel);
    }
    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showKeysLangLabel]);

  return { getLangLabel };
};

export const useOrgBySite = (data?: OrgBySiteApiType) => {
  const router = useRouter();
  const { orgConfig, setOrgConfig, setElegibleProducts } = useMain();
  const [googleTagId, setGoogleTagId] = useState<string | null>(null);

  try {
    useEffect(() => {
      const productsStorage = getProducts();
      if (productsStorage.length > 0) {
        setElegibleProducts(productsStorage);
      }

      if (data) {
        const dataOrg = data;
        const wlOrg = dataOrg?.orgId;
        const orgName = dataOrg?.orgName;

        setOrgConfig(dataOrg);

        setCookies("wlOrg", wlOrg);
        setCookies("orgid", wlOrg);
        setCookies("orgName", orgName);

        setGoogleTagId(dataOrg.googleTagId);
        const eligibleProducts = dataOrg?.eligibleProducts;
        if (Array.isArray(eligibleProducts) && eligibleProducts.length > 0) {
          const products = dataOrg.eligibleProducts.sort(
            compareValues("product_id", "asc")
          );
          setElegibleProducts(products);
          insertProducts(products);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, router.query]);
  } catch (error) {}

  return { orgConfig, googleTagId };
};

export const useDisplayPrice = (data: boolean) => {
  const { withTax, setWithTax } = useMain();
  const { currentUser } = useAuth();
  const currentUserGuest: boolean = currentUser?.role == "Guest";
  try {
    useEffect(() => {
      setWithTax(currentUserGuest ? true : data);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
  } catch (error) {}

  return [withTax];
};

export const useCurrencyRate = () => {
  const router = useRouter();
  const { setCurrencyRate } = useLayoutStore();

  try {
    useEffect(() => {
      const currentCurrency = checkCookies("currency_code")
        ? String(getCookie("currency_code"))
        : "USD";
      if (currentCurrency != "USD") {
        getCurrencyRate({
          fromCurrency: "USD",
          toCurrency: currentCurrency,
        })
          .then((c) => {
            if (c?.roeRate && c?.roeRate > 0) {
              setCurrencyRate(c?.roeRate);
            } else {
              setCurrencyRate(null);
            }
          })
          .catch(() => {
            setCurrencyRate(null);
          });
      }

      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query]);
  } catch (error) {}

  return [];
};

export const useFooter = (data?: FooterApiType) => {
  const { setFooter } = useLayoutStore();
  const { footer } = useLayoutStore(
    (state) => ({
      footer: state.footer,
    }),
    shallow
  );

  try {
    useEffect(() => {
      if (data) {
        setFooter(data);
      }
      return () => {};
    }, [data, setFooter]);
  } catch (error) {}

  return footer;
};

export const useConfig = (): ConfigApiType | null => {
  const { setConfig, setExternalConfig, fetcherUpdateWeekStartOn } =
    useLayoutStore();
  const { setWithTax } = useMain();
  const { config, refreshConfig } = useLayoutStore(
    (state) => ({
      config: state.config,
      refreshConfig: state.refreshConfig,
    }),
    shallow
  );
  const { currentUser } = useAuth();
  const currentUserGuest: boolean = currentUser?.role == "Guest";

  const setActionParams = (menu?: MenuType) => {
    // get params from window.location.search
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const action = urlParams.get("action"); // example: ?action=offbeat
      const sections = menu?.sections?.find((f) =>
        f?.items?.some((i) => i?.actionParams?.name == action)
      );

      if (action && sections) {
        const item = sections?.items?.find(
          (f) => f?.actionParams?.name == action
        );
        if (item) {
          const service = item?.actionParams?.service;
          if (service) {
            setCookies(COOKIE_BOOKING_OTHER_ORG, service);
          }
        }
      }
    } catch (error) {
      console.log("error setActionParams", error);
    }
  };

  try {
    useEffect(() => {
      if (!config) {
        getConfig().then((d) => {
          const config = d?.config;
          const externalConfig = d?.externalConfig;

          if (config) {
            !checkCookies("logOrg")
              ? setCookies("logOrg", externalConfig?.orgId)
              : null;
            !checkCookies("logUUID") ? setCookies("logUUID", uuidv4()) : null;
            !checkCookies("logEnv")
              ? setCookies("logEnv", externalConfig?.env)
              : null;
            setConfig(config);
            // fetcherExternalConfig(config?.orgBySite.orgId);
            setWithTax(
              currentUserGuest ? true : config?.displayPrice == WITH_TAX
            );
            fetcherUpdateWeekStartOn(
              config?.orgBySite.orgCountryData?.calendarType ?? "Sun"
            );

            const orgCountryCode = config?.orgBySite?.orgCountryData?.code;
            if (orgCountryCode) {
              setCookies("orgCountryCode", orgCountryCode);
            }
          }
          if (externalConfig) {
            setExternalConfig(externalConfig);
            setActionParams(externalConfig?.menu);
          }
        });
      }
      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshConfig]);
  } catch (error) {}

  return config;
};

export const useCheckLogin = (): ConfigApiType | null => {
  const router = useRouter();
  const { setUserType } = useMain();
  const { setCreditLimit } = useUserProfile();
  const { currentUser, saveUser, setCookiesAuth } = useAuth();
  // setShowUpgradeUserModal,
  const { setIsLoadedCheckLogin, setDisplayPrice } = useLayoutStore();
  // , setPlansAvailable
  const { setPlanStatus } = useMembershipUpgradeStore();
  const { config, isLoadedCheckLogin, plansv2Data } = useLayoutStore(
    (state) => ({
      config: state.config,
      isLoadedCheckLogin: state.isLoadedCheckLogin,
      plansv2Data: state.plansv2Data,
    }),
    shallow
  );
  // const { cashCardApplied, couponCodeApplied } = useWeeksCheckoutStore(
  //   (state) => ({
  //     cashCardApplied: state.cashCardApplied,
  //     couponCodeApplied: state.couponCodeApplied,
  //   }),
  //   shallow
  // );

  // const showPlansModal = (flag: boolean) => {
  //   console.log("no hay plan-2-3", flag);
  //   setShowUpgradeUserModal(flag);
  // };
  // const setAvailablePlans = (plans: PlansApiDataType[] | null) => {
  //   setPlansAvailable(plans);
  // };
  try {
    useEffect(() => {
      // get params from window.location.search
      const urlParams = new URLSearchParams(window.location.search);
      const key = urlParams.get("key");
      const signup = urlParams.get("signup");
      // current pathname from window.location
      const pathname = window.location.pathname;
      const pageException = [
        "/login/sso",
        "/verify-account",
        "/change-password",
      ];

      if (
        !key &&
        !signup &&
        pageException.indexOf(pathname) === -1 &&
        config &&
        !isLoadedCheckLogin
      ) {
        setCookiesAuth();
        setIsLoadedCheckLogin(true);
        getCheckLogin().then(async (response) => {
          if (response && response?.status === "success") {
            // removeCookies("userData");

            if (response?.result) {
              console.log("checkloginResponse", response?.result);
              let userInfo = response?.result;
              const responseProf = await getUserProfileAPI();

              if (userInfo.subscription_details.length <= 0) {
                if (
                  responseProf?.basicprofile &&
                  responseProf?.basicprofile?.length > 0
                ) {
                  let currProf = responseProf?.basicprofile.find((f) => f);
                  if (currProf?.subscription_details) {
                    userInfo = {
                      ...userInfo,
                      subscription_details: currProf?.subscription_details,
                    };
                  }
                }
              }

              if (
                plansv2Data &&
                plansv2Data?.userProfile?.subscription_details?.length > 0
              ) {
                userInfo = {
                  ...userInfo,
                  subscription_details:
                    plansv2Data?.userProfile.subscription_details,
                };
              }

              if (userInfo?.display_price) {
                setDisplayPrice(userInfo?.display_price);
              }
              setUserType(Number(userInfo?.userType || "2"));
              setCreditLimit(Number(userInfo?.credit_limit || "0"));

              // let checkPlanResp = await PlanService.checkPlan(
              //   userInfo,
              //   showPlansModal,
              //   setAvailablePlans,
              //   currentUser,
              //   couponCodeApplied,
              //   String(cashCardApplied || "")
              // );

              const currProf = responseProf?.basicprofile.find((f) => f);
              let subsDetail = currProf?.subscription_details.find((f) => f);
              let checkPlanResp = subsDetail?.plan_status
                ? subsDetail?.plan_status
                : "NOPLAN";

              console.log("checkPlanResp", currProf, subsDetail, checkPlanResp);
              const countryId = currProf?.country || "235";
              const countryDate = getCountryById(Number(countryId));
              // check if is not empty countryDate?.countryCode
              if (countryDate?.countryCode) {
                setCookies("userCountryCode", countryDate?.countryCode);
              } else {
                if (!getCookie("userCountryCode")) {
                  if (getCookie("orgCountryCode")) {
                    setCookies("userCountryCode", getCookie("orgCountryCode"));
                  } else {
                    setCookies("userCountryCode", "US");
                  }
                }
              }

              if (checkPlanResp == "ACTIVE-REFRESH") {
                if (
                  responseProf?.basicprofile &&
                  responseProf?.basicprofile?.length > 0
                ) {
                  if (currProf?.subscription_details) {
                    userInfo = {
                      ...userInfo,
                      subscription_details: currProf?.subscription_details,
                    };
                  }
                }

                checkPlanResp = "ACTIVE";
              } else {
                if (
                  currProf?.subscription_details &&
                  currProf?.subscription_details.length > 0
                ) {
                  userInfo = {
                    ...userInfo,
                    subscription_details: currProf?.subscription_details,
                  };
                }
              }

              const planStatusValue =
                checkPlanResp?.toUpperCase() == "ACTIVE" ? true : false;
              setPlanStatus(planStatusValue);
              console.log("checkPlanResp value", planStatusValue);
              if (currentUser) {
                const cuserTmp: UserModelType = {
                  ...currentUser,
                  ...userInfo,
                };
                console.log("DAILY_CONFIG", DAILY_CONFIG);
                if (DAILY_CONFIG) {
                  DailyService.addNewDailyMember(cuserTmp);
                }
                console.log("sso check-Login 111111:", cuserTmp, currentUser);
                saveUser(cuserTmp);
                setCookies("userToken", currentUser.userToken);
              }
            }
          }

          if (response && response?.status === "failed") {
            const userInfo = response?.result;
            setUserType(Number(userInfo?.userType || "2"));

            if (
              response?.message?.ssocheck?.is_sso &&
              response?.message?.ssocheck?.is_sso === 1
            ) {
              const urlRedirect = response?.message?.session_out_url;

              if (urlRedirect) {
                router.push({ pathname: urlRedirect });
              }
            }
          }
        });
      }
      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config]);
  } catch (error) {}

  return config;
};

export const useCheckIp = () => {
  const { setIp } = useMain();
  try {
    useEffect(() => {
      if (!checkCookies("true-client-ip")) {
        getIp().then((myip) => {
          setCookies("true-client-ip", myip);
          setCookies("ip", myip);
          setIp(myip);
        });
      } else {
        const myip = String(getCookie("true-client-ip"));
        setCookies("true-client-ip", myip);
        setCookies("ip", myip);
        setIp(myip);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  } catch (error) {}
};

export const useNationality = () => {
  const router = useRouter();
  const { setNationality } = useLayoutStore();
  const { currentUser } = useAuth();
  const { nationality } = useLayoutStore(
    (state) => ({
      nationality: state.nationality,
    }),
    shallow
  );
  try {
    useEffect(() => {
      if (!nationality) {
        console.log(
          "🚀 ~ file: useLayout.tsx:531 ~ useEffect ~ nationality:",
          nationality
        );
        if (currentUser?.nationality) {
          getNationalitiesApi()
            .then((c) => {
              if (Array.isArray(c) && currentUser?.nationality) {
                const nationalityUser = c?.find(
                  (f) => f.nationalityId == Number(currentUser?.nationality)
                );
                if (nationalityUser) {
                  setNationality(nationalityUser?.nationalityCode || "US");
                  localStorage.setItem(
                    NATIONALITY_KEY,
                    nationalityUser?.nationalityCode || "US"
                  );
                }
              }
            })
            .catch(() => {
              setNationality("US");
              localStorage.setItem(NATIONALITY_KEY, "US");
            });
        }
      }

      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query]);
  } catch (error) {}

  return [];
};

export const useCheckUserLocation = () => {
  // const [isLoaded, setIsLoaded] = useState(false);
  try {
    useEffect(() => {
      if (!checkCookies("userGeolocation")) {
        console.log("userGeolocation --", getCookie("userGeolocation"));
        getGeolocationByIpApi().then((geo) => {
          setCookies("userGeolocation", {
            lat: geo?.geoplugin_latitude,
            long: geo?.geoplugin_longitude,
          });
          setCookies("userCountryCode", geo?.geoplugin_countryCode || "US");
          // setIsLoaded(true);
        });
      }
      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  } catch (error) {}
};

export const useCssPreAndPostLogin = () => {
  // const { isLogged, reloadCss } = useAuth();
  const setCssFile = (fileName: string | null) => {
    if (fileName) {
      var stylesheet = document.getElementById("lnkGlobalCss");
      stylesheet !== null && stylesheet?.parentNode?.removeChild(stylesheet);
      let style = document.createElement("link");
      style.id = "lnkGlobalCss";
      style.type = "text/css";
      style.rel = "stylesheet";
      style.crossOrigin = "anonymous";
      //style.href = config.default.businessUrl + 'getCss/' + cssFileName;
      // style.href = 'getCss/' + cssFileName;
      style.href = `/api/general/getCss/${encodeURIComponent(fileName)}`;

      document.head.appendChild(style);
    }
  };

  try {
    useEffect(() => {
      getCssPreAndPostLogin("Home_Page").then((cssData) => {
        const isPostLogin = cssData?.isPostLogin;
        if (isPostLogin) {
          const data = cssData?.data;
          const fileName = data && data?.length > 0 ? data[0].CSS_FILE : null;
          const logo = data && data?.length > 0 ? data[0].HEADER : null;
          setCookies("logo", logo);
          setCssFile(fileName);
        } else {
          const fileName = cssData?.COMMON_CSS_FILE || null;
          const logo = cssData?.HEADER || null;
          if (logo) {
            setCookies("logo", logo);
          }
          setCssFile(fileName);
        }
      });

      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  } catch (error) {}

  return [];
};

export const useContryList = () => {
  const { setCountryList } = useLayoutStore();
  const { countryList } = useLayoutStore(
    (state) => ({
      countryList: state.countryList,
    }),
    shallow
  );
  try {
    useEffect(() => {
      if (countryList.length == 0) {
        const countryCode = getCookie("userCountryCode");
        const countryCodeArray = countryCode ? [String(countryCode)] : [];
        if (getContriesList()?.length > 0) {
          const data = getContriesList();
          const aux = data.sort(compareValues("countryName", "asc"));
          setCountryList(orderCountryListByCountryCode(aux, countryCodeArray));
        } else {
          getCountryDataAPI().then((c) => {
            const aux = c.sort(compareValues("countryName", "asc"));
            const data = orderCountryListByCountryCode(aux, countryCodeArray);
            setCountriesList(data);
            setCountryList(data);
          });
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  } catch (error) {}

  return true;
};

export const useCurrencyRateMethods = () => {
  const { currencyRate } = useLayoutStore(
    (state) => ({
      currencyRate: state.currencyRate,
    }),
    shallow
  );

  const getCurrencyRateFrom = (
    priceOrigin: number,
    originalCurrency: string,
    digits = 2
  ) => {
    const currentCurrency = checkCookies("currency_code")
      ? getCookie("currency_code")
      : CURRENCY_CODE_BASE;
    let total = priceOrigin;
    try {
      if (
        String(currentCurrency).toLocaleLowerCase() ==
        String(originalCurrency).toLocaleLowerCase()
      ) {
        total = priceOrigin;
      } else if (currencyRate && priceOrigin) {
        total = currencyRate * priceOrigin;
      }
      if (total % 1 == 0) {
        return total;
      }
      return total.toFixed(digits);
    } catch (error) {
      console.log(
        "🚀 ~ file: useLayout.tsx:639 ~ useCurrencyRateMethods ~ error:",
        error
      );
      return String(priceOrigin || "0");
    }
  };

  return { getCurrencyRateFrom };
};
