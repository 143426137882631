import axios from "axios";

export const SignIn = async (
  jsonSingIn: SignInRequestType,
  ipAddress: string
): Promise<SignInResponseType> => {
  try {
    const navAgent = navigator.userAgent;
    const response = await axios.post(`/api/auth/signIn`, {
      jsonSingIn,
      ipAddress,
      navAgent,
    });
    return response.data;
  } catch (error) {
    console.info(error);
    return { result: null, status: null };
  }
};
