import {
  setCurrencyCookies,
  setCurrencyCookiesLogout,
  setLangCookies,
  setLangCookiesLogout,
} from "@helpers/helper";
import { getCookie, setCookies } from "cookies-next";

const setLogInInfo = (
  jsonInspira: any,
  proveniencia: "Facebook" | "Google" | "Mail",
  jsonLoggedType: any,
  currencies?: CurrencyDetailType[],
  languages?: LangDetailTypeApi[]
) => {
  //console.info(proveniencia, jsonInspira, jsonLoggedType);

  var userName = "";
  var userToken = "";
  var personalPicture = "";
  var email = "";
  var currency_code = "";
  var language = "";
  var nationality = "";
  var userId = "";
  var role = "";
  var last_name = "";
  var first_name = "";

  setCookies("userToken", jsonInspira?.token || "");

  userName = jsonInspira?.username || "";
  userToken = jsonInspira?.token || "";
  email = jsonInspira?.email || "";
  currency_code = jsonInspira?.currency_code || "";
  language = jsonInspira?.language || "";
  nationality = jsonInspira?.nationality || "";
  userId = jsonInspira?.userId || "";
  role = jsonInspira?.role || "";
  last_name = jsonInspira?.last_name || "";
  first_name = jsonInspira?.username || "";

  setCurrencyCookiesLogout(
    Number(getCookie("currency_id")),
    String(getCookie("currency_name")),
    String(getCookie("currency_code"))
  );
  setLangCookiesLogout(
    String(getCookie("lan_name")),
    String(getCookie("lan_code")),
    Number(getCookie("lan_id"))
  );
  if (currencies && currencies?.length > 0) {
    const findCurrencyUser = currencies.find((f) => f.code == currency_code);
    if (findCurrencyUser) {
      setCurrencyCookies(
        findCurrencyUser.id,
        findCurrencyUser.name,
        findCurrencyUser.code
      );
    }
  }
  if (languages && languages?.length > 0) {
    const findLangUser = languages.find((f) => f.code == language);
    if (findLangUser) {
      setLangCookies(findLangUser.name, findLangUser.code, findLangUser.id);
    }
  }

  if (proveniencia == "Google") {
    setCookies("userToken", jsonLoggedType.accessToken);
    userName = jsonLoggedType.profileObj.givenName;
    //userToken = jsonLoggedType.tokenObj.access_token;
    //userToken = jsonLoggedType.profileObj.googleId;
    personalPicture = jsonLoggedType.profileObj.imageUrl;
    email = jsonLoggedType.profileObj.email;
  }

  if (proveniencia == "Facebook") {
    setCookies("userToken", jsonLoggedType.accessToken);
    userName = jsonLoggedType.name.split(" ")[0]
      ? jsonLoggedType.name.split(" ")[0].trim()
      : jsonLoggedType.name;
    //userToken = jsonLoggedType.accessToken;
    personalPicture = jsonLoggedType?.picture?.data?.url || "";
    email = jsonLoggedType.email;
  }

  let jsonClient = {
    userName: userName,
    userToken: userToken,
    personalPicture: personalPicture,
    email: email,
    currency_code: currency_code,
    language: language,
    nationality: nationality,
    userId: userId,
    role: role,
    first_name,
    last_name,
    auth: {
      api_token: userToken,
    },
  };
  return jsonClient;
};

const getGoogleAppID = () => {
  return "41707890031-vetiql35lj39daa4pkgjpgclcabv6m38.apps.googleusercontent.com";
};

const getFacebookAppID = () => {
  return "473732433732571"; //"473732433732571";
};

const AuthService = {
  setLogInInfo,
  getGoogleAppID,
  getFacebookAppID,
};
export default AuthService;
