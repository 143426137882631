import axios from "axios";

export const getNationalitiesApi = async (): Promise<NationalityDataType[]> => {
  try {
    const response = await axios.post(`/api/general/getNationalities`);
    return response.data.data.data;
  } catch (error) {
    return [];
  }
};
