import styled from "styled-components";
import { Modal, Col, Row } from "react-bootstrap";

export const ModalCustom = styled(Modal)`
  background: rgba(64, 67, 120, 0.75);
  & .modal-content {
    border-radius: ${({ theme }: any) => (theme.isMobile ? "0px" : "30px")};
  }
  & .modal-dialog {
    max-width: 29rem;
  }
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    & .modal-dialog {
      max-width: 100%;
    }
  }
`;

export const RowCloseModalForgotPass = styled(Row)`
  justify-content: right;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  //margin-bottom: -3rem;
`;
export const SpanCloseModalForgotPass = styled.span`
  position: relative;
  width: 2.5rem;
  cursor: pointer;
`;

export const ImgCloseModalForgotPass = styled.img`
  content: url("/images/modalSignInSignUp/CloseBlue.svg");
  width: 1rem;
  // height: 1rem;
`;

export const ModalHeader = styled.div`
  padding: 3.7rem;
  margin-top: -2rem;
`;

export const RowMainTittle = styled(Row)`
  width: 100%;
  --bs-gutter-x: 0rem;
`;

export const MainTittle = styled.span`
  margin: 0 0 20px;
  text-align: left;
  color: #686ea3;
  font-size: 29px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

export const MainTittleP = styled.p`
  color: grey;
  line-height: 1.8;
  font-size: 14px;
`;

export const ModalBody = styled(Modal.Body)`
  padding-left: 3.7rem;
  padding-right: 3.7rem;
  margin-top: -4rem;
`;

export const RowMail = styled(Row)`
  width: 100%;
  --bs-gutter-x: 0rem;
`;

export const SpanEmailTittle = styled.span`
  text-align: left;
  color: #686ea3;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.3rem;
`;

export const InputMail = styled.input`
  position: relative;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0;
  height: 40px;
  padding-right: 55px;
  //background: #f6f8fb;
  border: 1px solid #e9e9ea;
  border-radius: 5px;
  padding-left: 15px;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5c5c5c;
  display: block;
  box-sizing: border-box;
  outline: none;
`;

export const SpanIconArroba = styled.span`
  position: absolute;
  margin-top: 0.4rem;
  right: ${({ theme }: any) => (theme.isMobile ? "-18.3rem" : "-23rem")};
  cursor: pointer;
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    text-align: right;
    right: 6rem;
  }
`;

export const ImgIconArroba = styled.img`
  content: url("/images/modalSignInSignUp/arrobaIcon.svg");
  width: 1.3rem;
`;

export const DivFooder = styled.div`
  padding-left: 3.7rem;
  padding-right: 3.7rem;
  margin-top: ${({ theme }: any) => (theme.isMobile ? "0.4rem" : "0.4rem")};
`;
export const FooderModalForgotPass = styled(Modal.Footer)`
  //border: 1px solid red;
  margin-top: ${({ theme }: any) => (theme.isMobile ? "0rem" : "")};
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    margin-top: -5rem;
  }
`;

export const RowFooderForgotPass = styled(Row)`
  width: 100%;
  --bs-gutter-x: 0rem;
  margin-top: 0.5rem;
`;

export const ColFooderForgotPass = styled(Col)`
  justify-content: center;
`;

export const BtnSubmit = styled.button`
  color: white;
  width: 100%;
  height: 2.5rem;
  text-align: center;
  margin-right: 0;
  background: #424577;
  border-radius: 24px;
  font-weight: 700;
`;

export const InputBackSignIn = styled.button`
  border: none;
  background-color: transparent;
  text-align: center;
  width: 100%;
  color: #424577;
  font-size: 14px;
  font-weight: 700;
`;

export const SpanBackSignIn = styled.span`
  position: absolute;
  margin-top: 0rem;
  text-align: left;
  cursor: pointer;
`;
export const ImgBackSignIn = styled.img`
  content: url("/images/modalSignInSignUp/BackBlue.svg");
  width: 1.7rem;
  height: 1.5rem;
`;

export const DivMessageSuccess = styled.div`
  color: #41e58a;
  padding: 0 0 15px;
  font-weight: 600;
  font-size: 14px;
`;
export const DivMessageError = styled.div`
  color: red;
  padding: 0 0 15px;
  font-weight: 600;
  font-size: 14px;
`;
