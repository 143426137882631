import axios from "axios";

export const getwhitelabelcss = async (
  pageCode?: string
): Promise<CssPostLoginDataType | null> => {
  try {
    const response = await axios.post(`/api/general/getwhitelabelcss`, {
      pageCode,
    });
    return response.data;
  } catch (error) {
    console.info("getwhitelabelcss", error);
    return null;
  }
};

export const getCssPreAndPostLogin = async (
  pageCode?: string
): Promise<CssPostLoginDataType | ResponsePreloginType | null> => {
  try {
    const response = await axios.post(`/api/general/getCssPreAndPostLogin`, {
      pageCode,
    });
    return response.data;
  } catch (error) {
    console.info("getwhitelabelcss", error);
    return null;
  }
};
