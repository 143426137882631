import { create } from "zustand";
interface CurrencyLangConfigState {
  currencies: CurrencyDetailType[];
  currenciesDefault?: CurrencyDetailType | null;
  currentCurrency?: CurrencyDetailType | null;
  languages: LangDetailTypeApi[]; // ! cambiar
  languageDefault?: LangDetailTypeApi | null; // ! cambiar
  currentLanguage?: LangDetailTypeApi | null;
  setCurrencies: (value: CurrencyDetailType[]) => void;
  setCurrenciesDefault: (value: CurrencyDetailType) => void;
  setLanguages: (value: LangDetailTypeApi[]) => void; // ! cambiar
  setLanguageDefault: (value: LangDetailTypeApi) => void; // ! cambiar
  setCurrentCurrency: (value: CurrencyDetailType) => void; // ! cambiar
  setCurrentLanguage: (value: LangDetailTypeApi) => void; // ! cambiar
}

export const useCurrencyLangConfigStore = create<CurrencyLangConfigState>(
  (set) => ({
    currencies: [],
    currenciesDefault: null,
    languages: [],
    languagesDefault: null,
    currentCurrency: null,
    currentLanguage: null,
    setCurrencies: (value: CurrencyDetailType[]) => set({ currencies: value }),
    setCurrenciesDefault: (value: CurrencyDetailType) =>
      set({ currenciesDefault: value }),
    setLanguages: (value: LangDetailTypeApi[]) => set({ languages: value }),
    setLanguageDefault: (value: LangDetailTypeApi) =>
      set({ languageDefault: value }),
    setCurrentCurrency: (value: CurrencyDetailType) =>
      set({ currentCurrency: value }),
    setCurrentLanguage: (value: LangDetailTypeApi) =>
      set({ currentLanguage: value }),
  })
);
