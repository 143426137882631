import axios from "axios";

export const getCheckLogin =
  async (): Promise<CheckLoginResponseType | null> => {
    try {
      const response = await axios.post(`/api/auth/checkLogin`, {});
      return response.data;
    } catch (error) {
      console.info(error);
      return null;
    }
  };
