import axios from "axios";

type reservationVacationProps = {
  token: string;
};
export const getVacationReservation = async (
  data: reservationVacationProps
): Promise<myVacationReservationResponse | null> => {
  const json = {
    token: data.token,
  };
  try {
    const response = await axios.post(`/api/reservations`, { json });
    return response?.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};
