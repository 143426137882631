import axios from "axios";

export const getOrgBySite = async (): Promise<OrgBySiteResponseType | null> => {
  try {
    const response = await axios.post(`/api/general/getOrgBySite`, {});
    return response.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};
