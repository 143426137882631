import { IMAGE_PATH } from "@helpers/config";
import Image from "next/image";
import { Button, Col, Modal, Row } from "react-bootstrap";
import styled from "styled-components";

export const ModalCustom = styled(Modal)`
  background: rgba(64, 67, 120, 0.75);

  & > .modal-lg {
    max-width: 700px;
    margin-top: 1.2em;
  }
  & .modal-content {
    border-radius: 30px !important;
    min-height: 600px;
  }

  @media only screen and (max-width: 992px) {
    background: #fff;
    background-clip: padding-box;
    box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);

    & .modal-content {
      height: 100vh;
      border-radius: 0px;
    }

    & .modal-dialog {
      margin: 0;
      max-width: 100%;
    }
  }
`;

export const ModalHeader = styled(Modal.Header)`
  height: 3.7rem;

  border-radius: 35px 35px 0px 0px;
  background-color: #e7e9eb;
`;

export const ModalTitle = styled(Modal.Title)`
  padding-left: 3.4rem;
  line-height: 2.8rem;
  color: #686ea3;
  font-size: 17px;
  font-weight: 700;

  @media only screen and (max-width: 992px) {
    font-size: 24px; //medium
    font-weight: 600;
    color: #686ea3;
    padding-left: 1.8rem;
    margin: 0;
    width: 100%;
  }
`;

export const CloseM = styled.div`
  width: 1rem;
  height: 0.9rem;
  position: absolute;
  cursor: pointer;
  top: 1.15rem;
  left: 1.3rem;
`;

export const CloseModall = styled(Image)``;

export const Selected = styled(Col)`
  background-image: ${({ theme }) => {
    if (theme.flag) return `url(${IMAGE_PATH}${theme.flag})`;

    return `url(/images/general/no-img-found.png)`;
  }};
  background-repeat: no-repeat;
  background-position: left 3rem center;
  background-size: 30px 25px;

  background-color: var(--bs-gray-200);
  border-radius: 2rem;
  height: 2.7rem;
  display: list-item; /* This has to be "list-item"                                               */
  list-style-position: inside;
  line-height: 2.3rem;
  padding-left: 2rem;
  font-weight: 500;
  margin: 1.5rem 2rem;
  margin-top: 1rem;
`;
export const Span = styled.span`
  font-size: medium;
  margin-left: 2.5rem;
  //color: var(--bs-gray-700);
  color: var(--bs-gray-600);
  font-weight: 500;
`;

export const BtnSelected = styled(Button)`
  background-color: ${({ theme }) => {
    if (theme.isSelected)
      return `
      var(--bs-gray-200);
      display: list-item;
    `;
    return `transparent`;
  }};
  // revert this comments when exist flags
  /* background-image: ${({ theme }) => {
    if (theme.flag) return `url(${IMAGE_PATH}${theme.flag})`;

    return `url(/images/general/no-img-found.png)`;
  }};
  background-repeat: no-repeat;
  background-position: left 2.6rem center;
  background-size: 30px 25px; */

  border: none;
  height: 3rem;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: left;
  color: #404378;

  padding-left: 2.5rem;
  border-radius: 2rem;

  :hover,
  :focus,
  :active {
    color: #404378;
    box-shadow: none;
    background-color: var(--bs-gray-200);
  }
`;

export const RowBody = styled(Row)`
  background: #edf2f6;
  color: #686ea3;
  padding: 0.8rem 2rem;
  font-weight: 700;
`;

export const ModalBody = styled(Modal.Body)`
  border-radius: 35px;
`;

export const ColItem = styled(Col)`
  padding: 1rem;
`;
