import axios from "axios";

export const getIp = async () => {
  try {
    const res = await axios.get(
      "https://hotels.inspiravacations.com/cdn-cgi/trace",
      {
        headers: {
          "Content-Type": "application/json",
        },
        // withCredentials: true,
      }
    );

    if (res.data) {
      let input = res.data;
      var output = input.split("\n").reduce(function (o: any, pair: any) {
        pair = pair.split("=");
        return (o[pair[0]] = pair[1]), o;
      }, {});
      // console.info('ipAddress', output);
      return output.ip;
    }
  } catch (error: any) {
    return "";
  }
};
