/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import { ModalSignIn } from "../Header/ModalSignIn";
import { useMain } from "@contexts/mainContext";
import { ModalSignUp } from "../Header/ModalSignUp";
import codeResponse from "@data/codeResponseSignUp/codeResponse.json";
import ModalForgotPassword from "../Header/ModalForgotPassword";
import { useBreakpoints } from "@hooks/useBreakpoints";

export default function SigninSigup() {
  const {
    showModalSignIn,
    functSetShowModalSignIn,
    signUpShow,
    setSignUpShow,
    orgConfig,
  } = useMain();
  const [showModalForgotPass, setShowModalForgotPass] = useState(false);
  const [showMailRegister, setShowMailRegister] = useState(false);
  const [showMailRegisterSignUpMode, setShowMailRegisterSignUpMode] =
    useState<any>(null);
  const [bodyPassword, setBodyPassword] = useState(false);
  const [messageSignUp, setMessageSignUp] = useState("");
  const [showSignUpOptions, setShowSignUpOptions] = useState<boolean>(true);
  const [infoInvitation, setInfoInvitation] = useState<any>(null);
  const [isMobile, setIsMobile] = useState(false);
  const { breakpoints, width, height } = useBreakpoints();

  const handleSetShowSignUpOptions = (state: boolean) => {
    setShowSignUpOptions(state);
  };

  const getMessageSignUp = (codeMessage: string) => {
    let messageFound = codeMessage;
    codeResponse.data?.data?.map((obj: any) => {
      if (obj.code == codeMessage) {
        messageFound = obj.message;
      }
    });

    return messageFound;
  };
  const handelSetMessageSignUp = (codeMessage: string) => {
    const message = getMessageSignUp(codeMessage);
    setMessageSignUp(message);
  };

  const handleCloseModalSignUp = () => {
    setSignUpShow(false);
    setTimeout(() => {
      setShowMailRegister(false);
      setShowMailRegisterSignUpMode(false);
      setBodyPassword(false);
      setMessageSignUp("");
      handleSetShowSignUpOptions(true);
      handelSetMessageSignUp("");
    }, 500);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const type = params.get("type");
    if (true) {
      if (type == "email") {
        setShowMailRegister(true);
      }
      const accesscode = params.get("accesscode");
      const email = params.get("email");
      const firstname = params.get("firstname");
      const lastname = params.get("lastname");
      const title = params.get("title");
      const countrycode = params.get("countrycode");
      const phonenumber = params.get("phonenumber");
      const lang = params.get("lang");
      const tripid = params.get("tripid");
      const signup = params.get("signup");
      const register = params.get("register");
      const login = params.get("login");

      if (
        (accesscode &&
          email &&
          firstname &&
          lastname &&
          title &&
          countrycode &&
          phonenumber &&
          lang &&
          tripid) ||
        signup == "true" ||
        register == "true"
      ) {
        setInfoInvitation({
          mastercode: accesscode || "",
          email: email || "",
          lang: lang || "",
          tripid: tripid || "",
          lastname: lastname || "",
          firstname: firstname || "",
          title: title || "",
          countrycode: countrycode || "",
          phonenumber: phonenumber || "",
        });
      }
      if ((accesscode && email && tripid) || login == "true") {
        functSetShowModalSignIn(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (infoInvitation) {
      setSignUpShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoInvitation]);

  useEffect(() => {
    if (breakpoints.down("lg")) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    /* IPAD NORMAL */
    if (width == 768 && height == 1024) {
      setIsMobile(true);
    } else if (width == 1024 && height == 768) {
      setIsMobile(true);
    }
  }, [breakpoints, width, height]);

  return (
    <div className=" tw-w-full">
      {showModalSignIn && (
        <ModalSignIn
          show={showModalSignIn}
          handleCloseModalSignIn={() => functSetShowModalSignIn(false)}
          handleOpenModalForgotPass={() => setShowModalForgotPass(true)}
          handleOpenModalSignUp={() => setSignUpShow(true)}
          isMobile={false}
        />
      )}
      {signUpShow && (
        <ModalSignUp
          show={signUpShow}
          handleCloseModalSignUp={handleCloseModalSignUp}
          handleOpenModalSignIn={() => functSetShowModalSignIn(true)}
          isMobile={isMobile}
          showMailRegister={showMailRegister}
          hanndleSetShowMailRegister={(shows: boolean) => {
            setShowMailRegister(shows);
          }}
          showMailRegisterSignUpMode={showMailRegisterSignUpMode}
          handdleShowMailRegisterSignUpMode={(setInfo: any) => {
            setShowMailRegisterSignUpMode(setInfo);
          }}
          bodyPassword={bodyPassword}
          handdleSetBodyPassword={(setMode: boolean) => {
            setBodyPassword(setMode);
            setMessageSignUp("");
          }}
          accesscode={orgConfig?.orgMasterCode || ""}
          messageSignUp={messageSignUp}
          handelSetMessageSignUp={handelSetMessageSignUp}
          infoInvitation={infoInvitation}
          showSignUpOptions={showSignUpOptions}
          handleSetShowSignUpOptions={handleSetShowSignUpOptions}
        />
      )}
      {showModalForgotPass && (
        <ModalForgotPassword
          show={showModalForgotPass}
          handleCloseModalForgotPass={() => setShowModalForgotPass(false)}
          handleOpenModalSignIn={() => functSetShowModalSignIn(true)}
          isMobile={isMobile}
        />
      )}
    </div>
  );
}
