import { create } from "zustand";
import axios from "axios";

interface newUserProfile {
  userNewInfoProfile: UserProfileType;
  getUserInfoProfile: () => void;
  resetDataUserNewInfoProfile: () => void;
}

export const userProfileStore = create<newUserProfile>((set, get) => ({
  userNewInfoProfile: {
    basicprofile: [],
    cash: [],
    certificates: [],
    discount: [],
    promotions: [],
    travel: [],
    travelpreference: [],
  },
  getUserInfoProfile: async () => {
    const { userNewInfoProfile } = get();
    if (
      Array.isArray(userNewInfoProfile?.basicprofile) &&
      userNewInfoProfile?.basicprofile?.length == 0
    ) {
      try {
        const response = await axios.post("/api/profile/getNewUserProfile");
        const basicprofile: any[] = response?.data?.data?.basicprofile || [];
        if (Array.isArray(basicprofile) && basicprofile?.length > 0) {
          set({ userNewInfoProfile: response?.data?.data });
        }
      } catch (e: any) {
        console.log("/api/profile/getNewUserProfile", { error: e });
      }
    }
  },
  resetDataUserNewInfoProfile: () => {
    set({
      userNewInfoProfile: {
        basicprofile: [],
        cash: [],
        certificates: [],
        discount: [],
        promotions: [],
        travel: [],
        travelpreference: [],
      },
    });
  },
}));
