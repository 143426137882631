import axios from "axios";

export const updatePrefCurrLang = async (userDataRequest: any) => {
  try {
    const res = await axios.post(
      `/api/profile/updatePrefCurrLang`,
      userDataRequest
    );
    return res?.data;
  } catch (error) {
    return [];
  }
};
