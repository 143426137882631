/* eslint-disable @next/next/no-img-element */

import { useCurrencyLangInfo } from "@hooks/useLayout";
import { configStore } from "@store/configStore";
import ModalLanguage from "../Header/ModalLanguage";

export default function Languages() {
  const { languages } = useCurrencyLangInfo();
  const { setShowLanguageModal } = configStore();
  const { showLanguageModal } = configStore((state) => ({
    showCurrencyModal: state.showCurrencyModal,
    showLanguageModal: state.showLanguageModal,
  }));
  return (
    <div className=" tw-w-full">
      {languages.length > 0 && (
        <ModalLanguage
          show={showLanguageModal}
          handleClose={() => setShowLanguageModal(false)}
        />
      )}
    </div>
  );
}
