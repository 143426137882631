import styled from "styled-components";
import { Col, Container } from "react-bootstrap";
import { ScrollbarStyle } from "@styles/general";
import { IMAGE_PATH } from "@helpers/config";

export const OverlayContainer = styled(Container)`
  position: absolute;
  margin-top: 0.5rem;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 #aaa;
  background-color: #fff;
  width: 23.5rem;
  height: auto;
  max-height: 14rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem 1.4rem;
  ${ScrollbarStyle}
  scrollbar-width: 12px;
  ::-webkit-scrollbar {
    width: 12px;
  }
  z-index: 1;
  font-size: small;
  @media only screen and (max-width: 480px) {
    left: 3rem;
    font-size: x-small;
    width: 17.8rem;
  }
  @media only screen and (max-width: 992px) {
    ${({ theme }: any) =>
      () => {
        var CSSFinal = "";
        const origin = theme.origin;

        if (origin == "booking_history") {
          CSSFinal += `top: 0;
                       left: 0;
                       margin-top: 2.8rem;`;
        }
        return CSSFinal;
      }};
  }
  @media only screen and (min-width: 992px) {
    ${({ theme }: any) =>
      () => {
        var CSSFinal = "";
        const origin = theme.origin;

        if (origin == "booking_history") {
          CSSFinal += `top: 0;
                       left: 0;
                       margin-top: 2.8rem;`;
        }
        return CSSFinal;
      }};
  }
`;

export const ColOptions = styled(Col)`
  background-image: ${({ theme }) => `url(${IMAGE_PATH}${theme.icon})`};
  background-repeat: no-repeat;
  background-position: left 1rem center;
  background-size: 17px;
  padding-left: 3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  //font-size: small;
  font-weight: 500;
  border-radius: 10px;
  background-color: ${({ theme }: any) =>
    theme.activo ? "rgba(104, 110, 163, 0.4)" : "#fff"};
  color: ${({ theme }: any) => (theme.activo ? "#fff" : "var(--bs-gray-700)")};
  cursor: pointer;
  :hover {
    background-color: red;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #e9ecef;
  }
`;
export const NotAvailableContainer = styled.div`
  display: ${({ theme }: any) => (theme.show ? "" : "none")};
`;
export const NotAvailableLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: var(--var-inspira-purple2);
`;
