import { useEffect, useState } from "react";
import axios from "axios";
import { getOrgBySite } from "@lib/api/getOrgBySite";
import { getCookie } from "cookies-next";
import {
  ModalCustom,
  ModalHeader,
  RowMainTittle,
  MainTittle,
  MainTittleP,
  RowCloseModalForgotPass,
  SpanCloseModalForgotPass,
  ImgCloseModalForgotPass,
  ModalBody,
  RowMail,
  SpanEmailTittle,
  InputMail,
  SpanIconArroba,
  ImgIconArroba,
  DivFooder,
  FooderModalForgotPass,
  RowFooderForgotPass,
  ColFooderForgotPass,
  BtnSubmit,
  InputBackSignIn,
  SpanBackSignIn,
  ImgBackSignIn,
  DivMessageSuccess,
  DivMessageError,
} from "./styles";
import { useWhiteLabelLan } from "@hooks/useLayout";

type ModalForgotPassProps = {
  show: boolean;
  handleCloseModalForgotPass: any;
  handleOpenModalSignIn: any;
  isMobile: boolean;
};

const ModalForgotPassword = ({
  show,
  handleCloseModalForgotPass,
  handleOpenModalSignIn,
  isMobile,
}: ModalForgotPassProps) => {
  const [showError, setError] = useState("");
  const [showSuccess, setSuccess] = useState("");
  const [email, setEmail] = useState("");
  const [disabledBtnSumit, setDisableBtnSumit] = useState(false);
  const validateEmail = (email: String) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  const { getLangLabel } = useWhiteLabelLan();
  const [orgBySiteID, setOrgBySiteID] = useState<any>(null);
  const getOrgBySiteID = async () => {
    const response = await getOrgBySite();
    console.log("getOrgBySite: ", response);
    setOrgBySiteID(
      response?.data?.length || 0 > 0 ? response?.data[0].org_id : null
    );
  };
  useEffect(() => {
    if (!orgBySiteID) {
      getOrgBySiteID();
    }
  }, [orgBySiteID]);

  const executeResetPassword = async (orgBySiteID: any) => {
    if (validateEmail(email)) {
      let jsonResetPassword = {
        email: email,
        usr_type: 2,
        language: String(getCookie("lan_code") || "en-US"),
        org_id: orgBySiteID,
      };
      const response = await axios.post(`/api/signIn/requestResetPassword/`, {
        jsonResetPassword,
      });
      //console.log(response);
      if (response?.data?.data?.status == true) {
        setDisableBtnSumit(false);
        if (response?.data?.data?.data?.status == true) {
          setSuccess(response?.data?.data?.data?.msg);
        } else {
          setError(response?.data?.data?.data?.data);
        }
      } else {
        setError("Error server.");
      }
    } else {
      setDisableBtnSumit(false);
      setError("Please Enter Valid E-mail Address");
    }
  };

  return (
    <ModalCustom
      show={show}
      onHide={handleCloseModalForgotPass}
      fullscreen={isMobile}
      backdrop={"static"}
      keyboard={false}
      theme={{ isMobile }}
    >
      <RowCloseModalForgotPass>
        <SpanCloseModalForgotPass
          onClick={() => {
            handleCloseModalForgotPass();
          }}
        >
          <ImgCloseModalForgotPass />
        </SpanCloseModalForgotPass>
      </RowCloseModalForgotPass>
      <ModalHeader>
        <RowMainTittle>
          <MainTittle>{getLangLabel("RESET PASSWORD")}</MainTittle>
        </RowMainTittle>
        <RowMainTittle>
          <MainTittleP>
            {getLangLabel(
              "ENTER THE EMAIL ADDRESS ASSOCIATED WITH YOUR ACCOUNT, AND WE’LL EMAIL YOU A LINK TO RESET YOUR PASSWORD"
            )}
          </MainTittleP>
        </RowMainTittle>
      </ModalHeader>
      <ModalBody>
        <RowMail>
          <SpanEmailTittle>{getLangLabel("EMAIL")}</SpanEmailTittle>
        </RowMail>
        <RowMail>
          <InputMail
            type={"text"}
            placeholder={getLangLabel("EMAIL ADDRESS")}
            onChange={(e) => {
              setError("");
              setSuccess("");
              setEmail(String(e.target.value));
            }}
            value={email}
          />
          <SpanIconArroba theme={{ isMobile }}>
            <ImgIconArroba />
          </SpanIconArroba>
        </RowMail>
        <RowMail>
          {showError && <DivMessageError>{showError}</DivMessageError>}
          {showSuccess && <DivMessageSuccess>{showSuccess}</DivMessageSuccess>}
        </RowMail>
      </ModalBody>
      <DivFooder theme={{ isMobile }}>
        <FooderModalForgotPass theme={{ isMobile }}>
          <RowFooderForgotPass>
            <ColFooderForgotPass
              onClick={() => {
                handleCloseModalForgotPass();
                handleOpenModalSignIn();
              }}
            >
              <SpanBackSignIn>
                <ImgBackSignIn />
              </SpanBackSignIn>
              <InputBackSignIn>{getLangLabel("BACK LOG-IN")}</InputBackSignIn>
            </ColFooderForgotPass>
            <ColFooderForgotPass className={"p-0"}>
              <BtnSubmit
                onClick={() => {
                  setDisableBtnSumit(true);
                  setError("");
                  setSuccess("");
                  executeResetPassword(orgBySiteID);
                }}
                disabled={disabledBtnSumit}
                className="button-reset-password"
              >
                {getLangLabel("SUBMIT")}
              </BtnSubmit>
            </ColFooderForgotPass>
          </RowFooderForgotPass>
        </FooderModalForgotPass>
      </DivFooder>
    </ModalCustom>
  );
};

export default ModalForgotPassword;
